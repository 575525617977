<template>
  <div class="info">
    <div id="watermark">
      <van-form>
        <template v-if="changeType == 1">
          <van-cell-group class="info-second">
            <van-cell class="custom" >
              <template #title>
                <span class="custom-title">养殖信息</span>
                <template v-if="!!dataForm.id">
                  <van-button class="check" type="info" size="small" round @click="dataCheck" v-if="show.allCheck == 0">核查</van-button>
                  <van-button type="info" size="small" round @click="control.disable1=!control.disable1" v-if="control.disable1 == true">编辑</van-button>
                  <van-button type="info" size="small" round @click="onSubmit" v-if="control.disable1 == false">保存</van-button>
                </template>
                <!--                <div class="arrowIcon" @click="control.firstVisible=!control.firstVisible"><van-icon :name="control.firstVisible?'arrow-up':'arrow-down'"></van-icon></div>-->
              </template>
            </van-cell>
            <template v-if="control.firstVisible">
              <!--              <div class="scan-title" v-if="control.disable1==false">-->
              <!--                <div>扫描身份证件可自动生成个人信息</div>-->
              <!--                <van-uploader :after-read="cardIdIdentified">-->
              <!--                  <img :src="require('@/assets/img/scan.png')" alt="" class="scan">-->
              <!--                </van-uploader>-->
              <!--              </div>-->
              <template >
                <!--                <van-cell-->
                <!--                    class="required"-->
                <!--                    readonly-->
                <!--                    clickable-->
                <!--                    name="证件类型"-->
                <!--                    :value="show.idNumberTypeStr"-->
                <!--                    :value-class="{'value-common':show.idNumberTypeStr=='请选择'}"-->
                <!--                    title="证件类型"-->
                <!--                    placeholder="点击选择证件类型"-->
                <!--                    @click="showIdNumberType"-->
                <!--                    :is-link="!control.disable1"-->
                <!--                />-->
                <van-popup v-model="CommunityShow"  position="bottom"  >
                  <van-picker title="请选择" show-toolbar :columns="selectList.CommunityList" value-key="name" @confirm="CommunityConfirm"  @cancel="CommunityShow = false"/>
                </van-popup>
                <!--                v-if="(!dataForm.id && orgDepth == 4) || (dataForm.id && !userRes)"-->

                  <van-cell
                      v-if="orgDepth == 4"
                      class="required"
                      clickable
                      name="村社"
                      :value="show.orgStr"
                      title="所属社区"
                      :value-class="{'value-common':show.orgStr=='请选择'}"
                      placeholder="请选择所属社区"
                      :rules="[{ required: true }]"
                      @click="showOrg"
                      :is-link="!control.disable1"
                  />
                  <van-popup v-model="control.orgShow" position="bottom">
                    <van-picker
                        show-toolbar
                        value-key="label"
                        :columns="selectList.orgList"
                        @confirm="orgConfirm"
                        @cancel="control.orgShow = false"
                    />
                  </van-popup>
<!--                  <van-cell title="所在小区" :value="show.community" @click="CommunityShow = !control.disable1" :is-link="!control.disable1" required-->
<!--                            :value-class="{'value-common1':show.community=='请选择'}"/>-->

                <van-popup v-model="control.liveWhichCascader" position="bottom" >
<!--                  :orgId="dataForm.orgId"  :depth="depthHX"-->
                  <house-select @houseConfirm="closeHouseSelect" ></house-select>
                </van-popup>
                <van-cell   title="所在房屋" :value="show.house" @click="control.liveWhichCascader = !control.disable1" :is-link="!control.disable1" required
                           :value-class="{'value-common':show.house=='请选择'}"/>
                <van-cell title="房主" class="required">
                  <van-field
                      clearable
                      clear-trigger="always"
                      readonly
                      v-model="dataForm.name"
                      name="房主"
                      placeholder="请输入姓名"
                      :rules="[{ required: true }]"
                  />
                </van-cell>
                <van-cell title="证件号码" class="required" >
                  <van-field
                      clearable
                      readonly
                      @blur="getInfoByIdNumber"
                      v-model="dataForm.idNumber"
                      name="证件号码"
                      placeholder="请输入有效证件号"
                      :rules="[{ required: true }]"
                  />
                </van-cell>
              </template>

              <van-cell title="联系电话" :class="{required: dataForm.death != 1}">
                <van-field
                    clearable
                    readonly
                    v-model="dataForm.mobile"
                    name="联系电话"
                    placeholder="请输入联系电话"
                    :rules="[{ required: true }]"
                />
              </van-cell>


              <van-cell title="年份"
                        required
                        class="required"
                        clickable
                        name="年份"
                        :value="show.fgYear"
                        :value-class="{'value-common':show.fgYear=='请选择年份'}"
                        placeholder="请选择年份"
                        :rules="[{ required: true }]"
                        @click="yeartype"
                        :is-link="!control.disable1"
              >
              </van-cell>
              <van-popup v-model="control.fgYear" position="bottom">
                <van-picker
                    show-toolbar
                    value-key="label"
                    :columns="selectList.yearsList"
                    @confirm="yearConfirm"
                    @cancel="control.fgYear = false"
                />
              </van-popup>
              <van-cell title="季度"
                        required
                        class="required"
                        clickable
                        name="季度"
                        :value="show.fgQuarter"
                        :value-class="{'value-common':show.fgQuarter=='请选择季度'}"
                        placeholder="请选择季度"
                        :rules="[{ required: true }]"
                        @click="fgQuartertype"
                        :is-link="!control.disable1"
              >
              </van-cell>
              <van-popup v-model="control.fgQuarter" position="bottom">
                <van-picker
                    show-toolbar
                    value-key="label"
                    :columns="selectList.fgQuarterList"
                    @confirm="fgQuarterConfirm"
                    @cancel="control.fgQuarter = false"
                />
              </van-popup>
              <template v-if="isAdd">
                <van-cell v-for=" item in zoonList" :key="item.value">
                  <div class="titles" >{{item.parameters.parent?item.parameters.parent.label+'-'+item.label:item.label}}</div>
                  <template v-if="!item.children">
                    <van-col :span="16" class="newtitle" >
                      <van-field
                          @change="send2(item.value,item)"
                          oninput="if(value.length>6)value=value.slice(0,6)"
                          type="number"
                          placeholder="数量"
                          clearable
                          :readonly="control.disable1"
                          v-model="item.parameter"
                          :label="item.label+`(出栏数)`"
                          :rules="[{ required: true }]"
                      />
                    </van-col>
                    <van-col :span="16" class="newtitle" >
                      <van-field
                          @change="send2(item.value,item)"
                          oninput="if(value.length>6)value=value.slice(0,6)"
                          type="number"
                          placeholder="数量"
                          clearable
                          :readonly="control.disable1"
                          v-model="item.count"
                          :label="item.label+`(总数)`"
                          :rules="[{ required: true }]"
                      />
                    </van-col>
                  </template>
                  <template v-if="item.children">
                    <van-col :span="16" class="newtitle" v-for=" uk in item.children"  :key="uk.value">
                      <van-field
                          @change="send(item.value,uk)"
                          oninput="if(value.length>6)value=value.slice(0,6)"
                          type="number"
                          placeholder="数量"
                          clearable
                          :readonly="control.disable1"
                          v-model="uk.parameter"
                          :label="uk.label+`(出栏数)`"
                          :rules="[{ required: true }]"
                      />
                    <van-field
                        @change="send(item.value,uk)"
                        oninput="if(value.length>6)value=value.slice(0,6)"
                        type="number"
                        placeholder="数量"
                        clearable
                        :readonly="control.disable1"
                        v-model="uk.count"
                        :label="uk.label+`(总数)`"
                        :rules="[{ required: true }]"
                    />
                    </van-col>
                  </template>
                </van-cell>
              </template>

              <template v-if="!isAdd">
                <van-cell v-for=" item in zoonList" :key="item.value">
                  <div class="titles" >{{item.parameters.parent?item.parameters.parent.label+'-'+item.label:item.label}}</div>
                  <template v-if="!item.children&&item.parameters.numVo">
                    <van-col :span="16" class="newtitle" >
                      <van-field
                          @change="send3(item.value,item)"
                          oninput="if(value.length>6)value=value.slice(0,6)"
                          type="number"
                          placeholder="数量"
                          clearable
                          :readonly="control.disable1"
                          v-model="item.parameters.numVo.salesVolume"
                          :label="item.label+`(出栏数)`"
                          :rules="[{ required: true }]"
                      />
                    </van-col>
                    <van-col :span="16" class="newtitle" >
                      <van-field
                          @change="send3(item.value,item)"
                          oninput="if(value.length>6)value=value.slice(0,6)"
                          type="number"
                          placeholder="数量"
                          clearable
                          :readonly="control.disable1"
                          v-model="item.parameters.numVo.number"
                          :label="item.label+`(总数)`"
                          :rules="[{ required: true }]"
                      />
                    </van-col>
                  </template>
                  <template v-if="item.children">
                    <van-col :span="16" class="newtitle" v-for=" uk in item.children" :key="uk.value">
                      <van-field
                          @change="send1(item.value,uk)"
                          oninput="if(value.length>6)value=value.slice(0,6)"
                          type="number"
                          placeholder="数量"
                          clearable
                          :readonly="control.disable1"
                          v-model="uk.parameters.numVo.salesVolume"
                          :label="uk.label+`(出栏数)`"
                          :rules="[{ required: true }]"
                      />
                      <van-field
                          @change="send1(item.value,uk)"
                          oninput="if(value.length>6)value=value.slice(0,6)"
                          type="number"
                          placeholder="数量"
                          clearable
                          :readonly="control.disable1"
                          v-model="uk.parameters.numVo.number"
                          :label="uk.label+`(总数)`"
                          :rules="[{ required: true }]"
                      />
                    </van-col>
                  </template>
                </van-cell>
              </template>
            </template>

          </van-cell-group>

        </template>



        <div class="form-btn-view" v-if="!dataForm.id && (active==0||active==1 || active==2) && changeType == 1">
          <van-row gutter="10" class="btns" v-if="control.disabled">
            <van-col span="12">
              <van-button plain type="danger" size="large" round @click="moveOut">迁出</van-button>
            </van-col>
            <van-col span="12">
              <van-button type="info" size="large" round @click="redact">编辑</van-button>
            </van-col>
          </van-row>
          <van-row gutter="10" class="btns" v-else>
            <van-col span="24">
              <van-button round block type="info" native-type="submit"
                          @click="onSubmit()"
                          v-if="!control.disabled && !dataForm.id">确认添加
              </van-button>
              <van-button round block type="info"
                          @click="onSubmit()"
                          v-if="!control.disabled && dataForm.id">确认保存
              </van-button>
            </van-col>
            <!--            <van-col span="12">-->
            <!--              <van-button round block type="info" native-type="submit"-->
            <!--                          @click="submit(true)"-->
            <!--                          v-if="!control.disabled && !dataForm.id">添加并返回-->
            <!--              </van-button>-->
            <!--              <van-button round block type="info"-->
            <!--                          @click="submit(true)"-->
            <!--                          v-if="!control.disabled && dataForm.id">保存并返回-->
            <!--              </van-button>-->
            <!--            </van-col>-->
          </van-row>
        </div>
      </van-form>

    </div>
    <van-cell v-if="lnglatVisible" style="height: 330px;width: 700px"></van-cell>
    <lng-lat v-if="lnglatVisible" ref="lnglat" @closed="closed"></lng-lat>
    <nation v-if="control.nationShow" @setNation="setNation"/>
    <nationality v-if="control.nationalityShow" @setNationality="setNationality"/>
    <grid-select v-show="control.gridShow" :list="selectList.gridList" @selectLabel="selectGrid" :isArray="1" ref="gridSelect"></grid-select>

  </div>

</template>

<script>
import {mapState} from "vuex";
import {getImageStream} from "@/utils/index";
import {formatterDate, formatterMonth} from "@/utils/utils";
import {getBirthdayFromIdCard} from "@/utils/utils";
import {getSexFromIdCard} from "@/utils/utils";
import {getNativePlaceByIdCard} from "@/utils/utils";
import {identityCodeValid,desensitizationIdNumber,desensitization} from "@/utils/utils";
import {listComRightLabel} from "@/utils/common"
import {getbelongSubArea, getBelongCommunity} from "@/utils/common"
import {upload, getRealMobile} from "@/utils/common"
import {getVirtualDict, getDictTree, getbelongGrid} from "@/utils/common"
import {isNumber} from "@/utils/validate"
import petition from "@/views/userRes/petition";
import cause from "@/views/userRes/cause";
import nation from "@/components/nation/nation";
import nationality from "@/components/nation/nationality";
import fieldView from '../../../components/field-view/field-view.vue';
import GridSelect from '../../userRes/grid-select.vue'

import LngLat from "@/components/lnglat/index.vue";
import uploadFile from "@/components/upload/uploadFile.vue";

var that
export default {
  name: "info",
  components: {
    uploadFile,
    LngLat,
    nation,
    nationality,
    petition,
    cause,
    fieldView,
    GridSelect
  },
  data() {
    return {
      depth: parseInt(this.$globalData.userInfo.depth),
      numList:[],
      zoonList:[],
      lnglatVisible:false,
      urls: [],
      CommunityShow:false,
      showdeath:false,
      isAdd: false,
      cityData: [],
      death: false,
      collapse: ['1'],
      houseIndex: 0,
      changeType: 1,
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children',
      },
      depth:this.$globalData.userInfo.depth,
      dataForm: {
        orgIdCS:'',

        orgIdHZ:'',
        fgYear:'',
        fgQuarter:'',
        newFileMessages:[],
        deleteFiles:[],
        fileList:[],
        fileMessages:[],
        remark:'',
        form:'2',
        depth:'',
        contractor:'',
        houseId:'',
        forestCard:'',
        deviceUse:'',
        gridUserId:'',
        quantity:'',
        type:'',
        place:'',
        imgUrl: '',
        house:'',
        cardNumber:'',
        cardOwnerName:'',
        cardName:'',
        id: "",
        headImg: "",
        name: "",
        usedName: '',
        orgId: "",
        sex: "",
        nationality: 59,
        idNumberType: "1",
        idNumber: "",
        mobile: "",
        fixedLine: '',
        nation: 2,    //2是汉族
        birthday: "",
        company: "",
        insuredStatus: '',
        regAddress: "",
        regAddressCode: '',
        regAddressCodeName: '',
        nowAddress: "",
        nowAddressCode: '',
        nowAddressCodeName: '',
        // registryType: "",
        death: "0",
        deathDate: "",
        deathCard: '',
        cremationDate: '',
        cremationCard: '',
        deathAddress: '',
        deathReason: '',
        emContact: "",
        emMobile: "",
        resRemark: "",
        temporaryHeadImg: "",
        temporaryHeadImgSuffix: "",
        shorts: [],//简称连接
        labels: [],
        hometown: '',
        community: '',
        marriage: '',
        spouseIdNumberType: '',
        education: '',
        jobType: '',
        specialty: '',
        health: '',
        militaryType: '',
        political: '',
        religious: '',
        unemploymentReason: '',
        unemploymentTime: '',
        retirementType: '',
        retirementReason: '',
        pension: '',
        employment: '',
        job: '',
        professional: '',
        amateur: '',
        demand: '',
        licensed: '',
        birthdayCode: '',
        spouseIdNumber: '',
        userInfoAuxiliaryId: '',
        live: '',
        carList: [],
        mobileCheck: 0,
        nowAddressCheck: 0,
        regAddressCheck: 0,
        // moveInDate: '',
        communityList: [],
        gridId: 0,
        miniGridId: 0,
        nowAddressPcc: [],
        regAddressPcc: []
      },
      userRes: '',
      medicalData: {
        card: '',
        familyDoctor: {
          doctorId: '',
          doctorName: '',
          startTime: '',
          endTime: ''
        },
        hospitalVisits: {
          visitDate: '',
          diagnosis: ''
        },
        healthCheck: {
          summary: '',
          diagnosis: '',
          checkDate: '',
          suggest: ''
        },
        prescribeList: [],
        chronicDiseaseList: [],
        policyList: [],
        result: ''
      },
      loading: false,
      active: 0,
      show: {
        fgQuarter:'请选择',
        gridUser:'请选择',
        deviceUse:'请选择',
        type:'请选择',
        house:'请选择',
        idNumberTypeStr: "身份证",
        nationalityStr: "中国",
        nationStr: "汉族",
        headImg: require('@/assets/img/header.png'),//展示头像图
        registryTypeStr: "请选择",
        labelStr: '',
        Hometown: '请选择',
        community: '请选择',
        marriage: '请选择',
        spouseIdNumberType: '请选择',
        education: '请选择',
        political: '请选择',
        religious: '请选择',
        unemploymentTime: new Date(),
        employment: '请选择',
        live: '请选择',
        moveInDate: new Date(),
        healthStr: "请选择",
        militaryTypeStr: '请选择',
        insuredStatus: '请选择',
        retirementType: '请选择',
        pensionStr: '请选择',
        gridName: '请选择',
        jobType: '请选择',
        orgStr: '请选择',
        allCheck: null,
        birthdayStr: '请选择',
        regCodeStr: '请选择',
        nowCodeStr: '请选择',
        nowAddressPcc: '',
        regAddressPcc: '',
        fgYear:'请选择'
      },
      control: {
        fgYear:false,
        fgQuarter:false,
        gridUser:false,
        deviceUse:false,
        typeshow:false,
        liveWhichCascader:false,
        disabled: false,
        idNumberType: false,
        birthday: false,
        deathDate: false,
        cremationDate: false,
        label: false,
        showNew: true,
        subareaPopup: false,
        houseTreeCascader: false,
        relationship: false,
        useType: false,
        psychosis: false,
        registryType: false,
        sex: false,
        live: false,
        community: false,
        marriage: false,
        healthShow: false,
        spouseIdNumberType: false,
        education: false,
        jobType: false,
        militaryTypeShow: false,
        religious: false,
        Hometown: false,
        political: false,
        retirementType: false,
        pensionShow: false,
        unemploymentTime: false,
        employment: false,
        insuredStatus: false,
        nationShow: false,
        nationalityShow: false,
        slowVisible: true,
        policyVisible: true,
        prescribeVisible: false,
        diagnosisVisible: false,
        signVisible: false,
        testVisible: false,
        oldShow: false,
        moveInDate: false,
        firstVisible: true,
        secondVisible: true,
        thirdVisible: false,
        fourthVisible: false,
        fiveVisible: false,
        sixVisible: false,
        sevenVisible: false,
        disable1: false,
        disable2: false,
        disable3: false,
        disable4: false,
        disable5: false,
        disable6: false,
        disable7: false,
        disable8: false,
        showPopover: false,
        gridShow: false,
        orgShow: false,
        regCodeShow: false,
        nowCodeShow: false,
        nowAddressPcc: false,
        regAddressPcc: false
      },

      selectList: {
        yearsList:[],
        WgList:[],
        fgQuarterList:[
          {label:'第一季度',value:1},
          {label:'第二季度',value:2},
          {label:'第三季度',value:3},
          {label:'第四季度',value:4},
        ],
        shebeiTypeList:[],
        CommunityList:[],
        streetList:[],
        idNumberTypeList: [],
        houseTree: [],
        hometownList: [],
        communityList: [],
        spouseIdNumberTypeList: [],
        marriageList: [],
        educationList: [],
        jobTypeList: [],
        political: [],
        politicalList: [],
        religiousList: [],
        userCommunityLabelTree: [],
        healthList: [],

        militaryTypeList: [],
        insuredStatusList: [],
        retirementTypeList: [],
        actions: [{text: '该房屋所在网格为当前居民默认管理网格', className: 'gridPopover'}],
        familyList: [],
        socialList: [],
        gridList: [],
        orgList: [],
        regCodeList: [],
        nowCodeList: []
      },
      temporaryLabel: [],//临时标签
      labels: [],
      resHouses: [],
      checkedHouses: [],//目前选中的房屋
      orgDepth: 0,
      codeType: null,
      regCodeId: null,
      nowCodeId: null,
      isTipShow: false,
      tipValue: '',
      isNowAddressTipShow: false,
      nowAddressTipValue: '',
      flags: false, //控制使用
      position: {
        x: 0,
        y: 0,
      },
      nx: "",
      ny: "",
      dx: "",
      dy: "",
      xPum: "",
      yPum: "",
      houseName:''
    }
  },
  watch: {
    'dataForm.idNumber'(value, oldVal) {
      if (that.dataForm.idNumberType == 1) {
        if (value.length == 18 || value.length == 15) {
          // that.dataForm.birthday = getBirthdayFromIdCard(value);
          // that.dataForm.sex = getSexFromIdCard(value);
        }
      }
    },
    'dataForm.death'(value) {
      this.control.disabled=this.dataForm.death == 1 ? false : true
      this.death = this.dataForm.death == 1 ? true : false
      this.control.disable5=this.dataForm.death == 1 ? false : true
      this.showdeath = this.dataForm.death == 1 ? true : false
    },
    death() {
      this.dataForm.death = this.death ? 1 : 0
    },
    'dataForm.mobileInput': {
      handler(val, old) {
        this.dataForm.mobileCheck = 0
      }
    },
    'dataForm.nowAddress': {
      handler(val, old) {
        this.dataForm.nowAddressCheck = 0
      }
    },
    'dataForm.regAddress': {
      handler(val, old) {
        this.dataForm.regAddressCheck = 0
      }
    }
  },
  computed: {...mapState(['nationality', 'nation'])},
  created() {
    this.initYears()
    this.houseName=''
    if(this.$route.query.userId){
      //获取数据
      // this.getcontractorStr(this.$route.query.houseId)
      this.dataForm.houseId=this.$route.query.houseId
      this.show.house=this.$route.query.houseName
      this.houseName=this.$route.query.houseName

    }
    else{
      this.getSysInfo()
    }
    // this.getWgList()
    // this.getleader()
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    this.dataForm.unemploymentTime = formatterDate(new Date())
    this.orgDepth = this.$globalData.userInfo.depth
    this.codeType = this.$globalData.userInfo.codeType
    this.changeType = 1
    if (sessionStorage.getItem('changeType')) {
      this.changeType = parseInt(sessionStorage.getItem('changeType'))
    }
    that = this
    // 扫码跳转吧
    let options = this.$route.query


    if (options.houseFlag){
      this.control.showNew = false
      options.name = ''
      options.houseId = options.id
    }
    if (sessionStorage.getItem('showInfo')) {
      this.dataForm.id = sessionStorage.getItem('showInfo')
      sessionStorage.setItem('showInfo', '')
    } else {
      this.dataForm.id = parseInt(options.userId) || ''
    }
    if (!this.dataForm.id) {
      this.isAdd = true
    }
    this.dataForm.name = options.name || ""
    this.dataForm.idNumberType = 1 || ""
    this.show.idNumberTypeStr = "身份证" || ""
    this.dataForm.idNumber = options.idNumber || ""
    this.dataForm.regAddress = options.regAddress || ""
    this.dataForm.sex = options.sex == "男" ? 1 : 2 || ""
    this.dataForm.nation = options.nation || "2"
    this.show.nationStr = options.nationStr || "汉族"
    this.userName = this.$globalData.userInfo.userName
    this.mobile = this.$globalData.userInfo.mobile
    if(this.$orgId != this.$globalData.userInfo.street) {
      this.getBelong(0,this.$orgId)
    }

    if (options.houseId) {
      this.resHouses = [{
        id: 0,
        subarea: options.subarea,
        houseId: options.houseId,
        relationship: 0,
        useType: 0,
        nowLive: 1,
        remark: '',
        houses: [],
        moveOut: 0,
        registryTypeStr: "未知",
        subareaName: options.subareaName,
        fullName: options.fullName,
        relationshipStr: "请选择",
        useTypeStr: "请选择",
        status: 0,
        live: ''
      }]
      // this.$set(this.resHouses,this.resHouses)
      // this.houseChange(that.data.resHouses)
    }
    this.init()
    // 新房屋下居民点修改过来
    if(this.$route.query.editor && JSON.parse(this.$route.query.editor)) {
      this.control.disabled = false
    }

    if(this.$orgId == this.$globalData.userInfo.street) {
      // 如果是街道进入
      this.getBelong(1,this.$orgId)
      // this.$http({
      //   url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
      //   method: 'post',
      //   params: this.$http.adornParams({
      //     communityId: this.$orgId
      //   },false)
      // }).then(({data})=> {
      //   if (data.code == 0) {
      //     this.selectData.streetList = data.subAreaList
      //   } else {
      //     this.$toast.fail(data.msg);
      //   }
      // })
      this.isStreet = true
    }

  },
  methods: {

    send(id,val){
      let existingIndex = this.numList.findIndex(item => item.species === val.value);
      if(existingIndex!=-1){
        this.numList[existingIndex].number = val.count||0;
        this.numList[existingIndex].salesVolume = val.parameter||0;
      }
      else {
        let obj = {id:0, species:val.value||0, area:0, parentSpecies:id||0,number:val.count||0,salesVolume:val.parameter||0,userId:this.dataForm.userId||0,speciesName:val.label}
        this.numList.push(obj)
      }
      console.log(this.numList)
    },
    send1(id,val){
      let existingIndex = this.numList.findIndex(item => item.species === val.value);
      if(existingIndex!=-1){
        this.numList[existingIndex].number = val.parameters.numVo.number;
        this.numList[existingIndex].salesVolume = val.parameters.numVo.salesVolume;
      }
      else {
        let obj = {id:val.parameters.numVo.id||0, species:val.value, area:0, parentSpecies:id||0,number:val.parameters.numVo.number||0,salesVolume:val.parameters.numVo.salesVolume||0, userId:this.dataForm.userId||0,furiId:val.parameters.numVo.furiId||0,speciesName:val.label}

        this.numList.push(obj)
      }
      console.log(this.numList)
    },
    send2(id,val){

      let existingIndex = this.numList.findIndex(item => item.species === val.value);
      if(existingIndex!=-1){
        this.numList[existingIndex].number = val.count||0;
        this.numList[existingIndex].salesVolume = val.parameter||0;
      }
      else {
        let obj = {id:0, species:val.value||0, area:0, parentSpecies:id||0,number:val.count||0,salesVolume:val.parameter||0, userId:this.dataForm.userId||0,furiId:0,speciesName:val.label}
        this.numList.push(obj)
      }
      console.log(this.numList)
    },
    send3(id,val){
      console.log(val)
      let existingIndex = this.numList.findIndex(item => item.species === val.value);
      if(existingIndex!=-1){
        this.numList[existingIndex].number = val.parameters.numVo.number;
        this.numList[existingIndex].salesVolume = val.parameters.numVo.salesVolume;
      }
      else {
        let obj = {id:val.parameters.numVo.id||0, species:val.value, area:0, parentSpecies:id||0,number:val.parameters.numVo.number||0,salesVolume:val.parameters.numVo.salesVolume||0, userId:this.dataForm.userId||0,furiId:0,speciesName:val.label}
        this.numList.push(obj)
      }
      console.log(this.numList)
    },
    getSysInfo() {
      //新增获取
      if(!this.dataForm.id&&!this.readonly) {
        this.$http({
          url: this.$http.adornUrl('/wxapp/fAndG/fgSpeciesConfig/list'),
          method: 'get',
          params: this.$http.adornParams({
            'type': 2,
          })
        }).then(({data}) => {
          if (data && data.code == 0) {
            this.zoonList = data.list
            this.zoonList.forEach((item,index)=>{
              if(!item.children){
                item.parameter=''
                item.count=''
                this.send(item.value,item)
              }
              else{
                item.children.forEach((item2,index2)=>{
                  if(!item2.children){
                    item2.parameter=''
                    item2.count=''
                    this.send(item.value,item2)
                  }
                  else{
                    item2.children.forEach((item3,index3)=>{
                      item3.parameter=''
                      item3.count=''
                      this.send(item.value,item3)
                    })
                  }
                })
              }

            })
          }
        })
      }
    },
    //设置日期列表
    initYears(){
      var myDate = new Date;
      var year = myDate.getFullYear();//获取当前年
      this.initSelectYear(year)
    },
    initSelectYear(year) {
      this.selectList.yearsList = [];
      for (let i = 0; i < 30; i++) {
        this.selectList.yearsList.push({value: (year - i), label: (year - i) + "年"});
      }
    },
    filesUpload (files) {
      this.dataForm.newFileMessages = files
      this.dataForm.fileMessages = files
    },
    delFile (id) {
      this.dataForm.deleteFiles.push(id)
    },
    // 获取坐标窗口关闭回调
    closed (latlng){
      this.lnglatVisible = false
      this.dataForm.lat = latlng.lat
      this.dataForm.lng = latlng.lng
      this.dataForm.place = this.dataForm.lat + ',' + this.dataForm.lng
    },
    getLngLat () {
      this.lnglatVisible = true
      this.$nextTick(() => {
        this.$refs.lnglat.init(this.dataForm.orgId)
      })
    },
    getcontractorStr(value) {

      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/res/houseRes/getMaster`),
        method: 'get',
        params: this.$http.adornParams({
          houseId: value
        })
      }).then(({data}) => {
        if (data && data.code === 0&&data.userInfoEntity!=null) {
          // this.dataForm.id=data.userInfoEntity.id
          this.dataForm.name=data.userInfoEntity.name
          this.dataForm.contractor=data.userInfoEntity.id
          this.dataForm.idNumber=data.userInfoEntity.idNumber
          this.dataForm.mobile=data.userInfoEntity.mobile
        }

        else{
          this.dataForm.name='无户主信息'
          this.dataForm.contractor='无户主信息'
          this.dataForm.mobile='无户主信息'
          this.dataForm.idNumber='无户主信息'
        }
      })
    },
    closeHouseSelect (val, val2) {
      if(!val2){
        this.dataForm.orgId=this.$globalData.userInfo.orgId
        this.depthHX=this.$globalData.userInfo.depth
      }
      this.dataForm.name=''
      this.dataForm.contractor=''
      this.dataForm.mobile=''
      this.dataForm.idNumber=''
      if((val.isLeaf==true)&&(val.depth==4||val.depth==2)){
        this.dataForm.orgIdCS=val2[0].value
        this.dataForm.subarea=val.orgId
        this.getcontractorStr(val.houseId)
      }
      if(val2) {
        let i = val2.length - 1
        if (i >= 0) {
          this.show.house = val2[i].label
        }
      }
      else{
        this.dataForm.form=1
        this.show.house = '请选择'
      }
      this.control.liveWhichCascader = false
      this.dataForm = {... this.dataForm, ...val}
      if (this.$globalData.userInfo.depth == 4 && val2&&val2[0]) {
        this.dataForm.community = val2[0].value
        this.dataForm.gridId = ''
        this.dataForm.miniGridId = ''
      }
      if(this.$globalData.userInfo.depth == 4 && !val2){
        this.dataForm.community=''
      }

    },
    // 小区选择
    CommunityConfirm (value) {
      if (value) {
        this.dataForm.orgId=value.id
        this.depthHX=value.depth
        this.show.community = value.name
        this.dataForm.community = value.id
        this.dataForm.subarea = value.id
        this.dataForm.Building = '请选择'
        this.dataForm.unit = '请选择'
        this.selectList.unitList = []
        this.selectList.BuildingList = []
        this.$http({
          url: this.$http.adornUrl('/wxapp/building/info/getBySubArea'),
          method: 'post',
          params: this.$http.adornParams({
            subArea: value.id
          },false)
        }).then(({data})=> {
          if (data.code == 0) {
            this.selectList.BuildingList = data.buildings
          } else {
            this.$toast.fail(data.msg);
          }
        })
      }
      this.CommunityShow = false
    },
    getBelong(type,orgId) {
      console.log(type,orgId)
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
        method: 'post',
        params: this.$http.adornParams({
          communityId: orgId
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          if(type) {
            this.selectList.streetList = data.subAreaList
          }else {
            this.selectList.CommunityList = data.subAreaList
          }
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    nowAddressPccOverlay() {

    },
    nowAddressPccClose(e) {
      this.control.nowAddressPcc = false
    },
    nowAddressPccFinish(e) {
      this.dataForm.nowAddressPcc = e.selectedOptions.map(item => item.label)
      this.show.nowAddressPcc = e.selectedOptions.map(item => item.label).join('/')
    },
    regAddressPccPccOverlay() {

    },
    regAddressPccPccClose(e) {
      this.control.regAddressPcc = false
    },
    regAddressPccFinish(e) {
      this.dataForm.regAddressPcc = e.selectedOptions.map(item => item.label)
      this.show.regAddressPcc = e.selectedOptions.map(item => item.label).join('/')
    },
    down(event) {
      this.flags = true;
      var touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      this.position.x = touch.clientX;
      this.position.y = touch.clientY;
      this.dx = this.$refs.fu.offsetLeft;
      this.dy = this.$refs.fu.offsetTop;
    },
    move(event) {
      if (this.flags) {
        var touch;
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        this.nx = touch.clientX - this.position.x;
        this.ny = touch.clientY - this.position.y;
        this.xPum = this.dx + this.nx;
        this.yPum = this.dy + this.ny;
        let width = window.innerWidth - this.$refs.fu.offsetWidth; //屏幕宽度减去自身控件宽度
        let height = window.innerHeight - this.$refs.fu.offsetHeight; //屏幕高度减去自身控件高度
        this.xPum < 0 && (this.xPum = 0);
        this.yPum < 0 && (this.yPum = 0);
        this.xPum > width && (this.xPum = width);
        this.yPum > height && (this.yPum = height);
        this.$refs.fu.style.left = this.xPum + "px";
        this.$refs.fu.style.top = this.yPum + "px";
        //阻止页面的滑动默认事件
        document.addEventListener("touchmove",function (event) {
          event.preventDefault();
        },{passive:false});
      }
    },
    //鼠标释放时候的函数
    end() {
      this.flags = false;
    },
    getUserMobile(id) {
      getRealMobile(id,function(data) {
        window.location.href = `tel://${data.mobile}`
      })
    },
    changeCondition(type) {
      this.changeType = type
    },
    disableChange(val) {
      this.disable5 = val
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }
      return val;
    },
    inspect (name, value) {
      if (this.control.disabled || this.dataForm[name] == 1) {
        return
      }
      this.$dialog.confirm({
        title: '',
        center: true,
        message: `<p>您修改的<span style="color: #409eff">${value}</span>确认已核查过吗?</p>`,
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(action => {
        this.dataForm[name] = 1
      })
    },
    setNation(value) {
      console.log(value)
      if (value) {
        this.dataForm.nation = value.value
        this.show.nationStr = value.label
      }
      this.control.nationShow = false
    },
    setNationality(value) {
      this.dataForm.nationality = value.value
      this.show.nationalityStr = value.label
      this.control.nationalityShow = false
    },
    getCode(e,type) {
      this.isTipShow = false
      this.tipValue = ''
      this.isNowAddressTipShow = false
      this.nowAddressTipValue = ''
      let url = '/wxapp/sys/addressCodeMapping/listByName'
      let val = (type == 1 ? this.dataForm.regAddressCodeName : this.dataForm.nowAddressCodeName)
      let name = true
      if (isNumber(val)) {
        url = '/wxapp/sys/addressCodeMapping/infoByCode'
        name = false
      }
      this.$http({
        url: this.$http.adornUrl(url),
        method: 'post',
        params: this.$http.adornParams({
          name: name ? val : undefined,
          code: name ? undefined : val,
          type: 2
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let list = (data.list || data.sysAddressCodeMapping).map(item => {
            return {
              value: item.houseCode ? item.houseCode : item.doorCode,
              label: item.fullName,
              codeMappingId: item.id
            }
          })
          if (type == 1) {
            this.control.regCodeShow = true
            this.selectList.regCodeList = list
          } else {
            this.control.nowCodeShow = true
            this.selectList.nowCodeList = list
          }
        } else {
          if (type == 1) {
            this.isTipShow = true
            this.tipValue = data.msg
          } else {
            this.isNowAddressTipShow = true
            this.nowAddressTipValue = data.msg
          }
          // this.$toast.fail(data.msg)
        }
      })
    },
    codeConfirm (value, type){
      if (type == 1) {
        if (value) {
          this.show.regCodeStr = value.value;
          this.dataForm.regAddressCode = value.value;
          this.dataForm.regAddressCodeName = value.label;
          this.dataForm.regAddress = value.label
          this.selectList.regCodeList.map(item => {
            if (item.value == value.value) {
              this.regCodeId = item.codeMappingId
            }
          })
        }
        this.control.regCodeShow = false;
      } else {
        if (value) {
          this.show.nowCodeStr = value.value;
          this.dataForm.nowAddressCode = value.value;
          this.dataForm.nowAddressCodeName = value.label;
          this.dataForm.nowAddress = value.label
          this.selectList.nowCodeList.map(item => {
            if (item.value == value.value) {
              this.nowCodeId = item.codeMappingId
            }
          })
        }
        this.control.nowCodeShow = false;
      }
    },
    init() {
      this.selectInfo()
      console.log(this.$globalData.result)
      if (this.$globalData.result && this.$globalData.userInfo.county === '12345') {
        let code = this.$globalData.result
        this.control.oldShow = true
        this.control.disabled = true
        this.active = 3
        this.getMedicalUser(code)
      }else if (this.$globalData.result) {
        this.dataForm.id = this.$globalData.result
      }
      if (this.dataForm.id) {
        (this.control.disable1 = true, this.control.disable2 = true, this.control.disable3 = true,
            this.control.disable4 = true, this.control.disable5 = true, this.control.disable6 = true,this.control.disable7 = true, this.control.disable8 = true);
        this.control.disabled = true
        this.dataInfo()
      } else {
        that.dataForm.community = this.$globalData.userInfo.orgId
        this.dataForm.orgId = this.$globalData.userInfo.orgId
        this.$nextTick(() => {
          // this.$refs.fieldView.init(null, 1, this.$globalData.userInfo.orgId)
        })
        //新建的，获取中国
      }
    },
    sixChange() {
      this.control.sixVisible = !this.control.sixVisible
      if (this.control.sixVisible) {
        this.$nextTick(() => {
          this.$refs.fieldView.init(this.dataForm.id, 1, this.$globalData.userInfo.orgId)
        })
      }
    },
    async submit(isReturn) {
      console.log(this.dataForm.mobileCheck)
      let labels = this.dataForm.labels.join(',')
      let nativePlace = this.dataForm.hometown

      let numberBool = identityCodeValid(that.dataForm.idNumber)
      if (numberBool == '身份证号格式错误' && that.dataForm.idNumberType == 1 && that.dataForm.idNumber.indexOf('*') == -1) {
        return that.$toast.fail(numberBool)
      }
      if (this.dataForm.name.trim() == '') {
        return this.$toast.fail('请输入姓名')
      }
      if (this.dataForm.idNumber.trim() == '' && that.show.idNumberTypeStr != '无证件') {
        return this.$toast.fail('请输入有效证件号')
      }
      if (this.dataForm.mobile.trim() == '' && this.dataForm.death != 1) {
        return this.$toast.fail('请输入手机号码')
      }

      if (((!this.dataForm.id && this.orgDepth == 4) || (this.dataForm.id && !this.userRes)) && this.show.orgStr=='请选择') {
        return this.$toast.fail('请选择所属社区')
      }
      let extendInfoList = null
      // 自定义字段参数
      if (this.$refs.fieldView) {
        extendInfoList=   await this.$refs.fieldView.submitForm()
      }

      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: '提交中...',
      })
      let employmentDemand = this.dataForm.demand
      let licensedinformation = this.dataForm.licensed
      let isWish = this.dataForm.employment
      let id = this.dataForm.userInfoAuxiliaryId
      this.$http({
        url: this.$http.adornUrl(`/wxapp/app/userinfoauxiliary/save`),
        method: 'post',
        data: this.$http.adornData({
          amateur: this.dataForm.amateur,
          employmentDemand: employmentDemand,
          id: id,
          isWish: isWish,
          licensedinformation: licensedinformation,
          professional: this.dataForm.professional,
          registerTime: this.dataForm.registerTime,
          userId: this.dataForm.id
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.$http({
            url: this.$http.adornUrl(`/wxapp/user/save`),
            method: 'post',
            data: this.$http.adornData({...this.dataForm, labels, nativePlace, extendInfoList,
              nowAddressProvince: this.dataForm.nowAddressPcc[0] || '',
              nowAddressCity: this.dataForm.nowAddressPcc[1] || '',
              nowAddressCounty: this.dataForm.nowAddressPcc[2] || '',
              regAddressProvince: this.dataForm.regAddressPcc[0] || '',
              regAddressCity: this.dataForm.regAddressPcc[1] || '',
              regAddressCounty: this.dataForm.regAddressPcc[2] || ''
            })
          }).then(({data}) => {
            if (data.code == 0) {
              this.control.disabled = true
              this.dataForm.id = data.userId
              this.control.disable1 = true
              this.control.disable2 = true
              this.control.disable3 = true
              this.control.disable4 = true
              this.control.disable5 = true
              this.control.disable6 = true
              this.control.disable7 = true
              this.control.disable8 = true
              if (this.resHouses.length != 0) {
                this.resHouses.forEach(item => {
                  if (!item.userId) {
                    item.userId = this.dataForm.id
                  }
                  if (item.live == '否') {
                    item.nowLive = 0
                  } else {
                    item.nowLive = 1
                  }
                })
                this.$http({
                  url: this.$http.adornUrl(`/wxapp/user/res/houseRes/saveOrUpdate`),
                  method: 'post',
                  data: this.$http.adornData({resHouses: JSON.stringify(this.resHouses)})
                }).then(({data}) => {
                  this.$toast.clear()
                  if (data.code == 0) {
                    this.$toast.success({
                      message: '成功',
                      duration: 1500,
                      onClose: () => {
                        if (isReturn) {
                          this.$router.go(-1)
                        }
                      }
                    })
                  }
                })
              } else {
                this.$toast.clear()
                this.$toast.success({
                  message: '成功',
                  duration: 1500,

                })
                // this.active = 2
                // this.$toast.fail({
                //   message: '请添加房屋',
                //   duration: 1500
                // })
              }
            }
          })
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    dataInfo() {
      this.getInfoById();
      this.resHousesInfo();
      this.getUserinfoauxiliary()
      this.getCarList();
      this.getSocialList()
    },

    getSocialList() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/socialRelations/listByUser`),
        method: 'post',
        params: this.$http.adornParams({
          userId: this.dataForm.id,
        })
      }).then(({data}) => {
        if (data.code == 0) {
          that.selectList.socialList = data.list;
        }
      })
    },
    getMedicalUser(code) {
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
      });
      this.$http({
        url: this.$http.adornUrl(`/wxapp/QTXQ/getByCode`),
        method: 'post',
        params: this.$http.adornParams({
          code: code
        })
      }).then(({data}) => {
        if (data.code == 0) {
          let result = data.result.id_card
          that.medicalData.card = data.result.medical_card_no
          if (data.result.family_doctor !== null ) {
            that.medicalData.familyDoctor.doctorId = data.result.family_doctor.doctor_id
            that.medicalData.familyDoctor.doctorName = data.result.family_doctor.doctor_name
            let arr = data.result.family_doctor.sign_start_date.split(' ')[0].split('/')
            if (arr[1] <= 9) {
              arr[1] = '0' + arr[1]
            }
            if (arr[0] <= 9) {
              arr[0] = '0' + arr[0]
            }
            let startDate = arr[2] + '-' + arr[1] + '-' + arr[0]
            that.medicalData.familyDoctor.startTime = startDate

            let arr2 = data.result.family_doctor.sign_end_date.split(' ')[0].split('/')
            if (arr2[1] <= 9) {
              arr2[1] = '0' + arr2[1]
            }
            if (arr2[0] <= 9) {
              arr2[0] = '0' + arr2[0]
            }
            let endDate = arr2[2] + '-' + arr2[1] + '-' + arr2[0]
            that.medicalData.familyDoctor.endTime = endDate
          }
          that.medicalData.hospitalVisits.visitDate = data.result.hospital_visits !== null ? data.result.hospital_visits.visit_date : ''
          that.medicalData.hospitalVisits.diagnosis = data.result.hospital_visits !== null ? data.result.hospital_visits.diagnosis : ''
          that.medicalData.healthCheck.summary = data.result.health_check !== null ? data.result.healthCheck.summary : ''
          that.medicalData.healthCheck.diagnosis = data.result.health_check !== null ? data.result.healthCheck.diagnosis : ''
          that.medicalData.healthCheck.checkDate = data.result.health_check !== null ? data.result.healthCheck.check_date : ''
          that.medicalData.healthCheck.suggest = data.result.health_check !== null ? data.result.healthCheck.suggest : ''
          that.medicalData.chronicDiseaseList = data.result.chronic_disease_list !== null ? data.result.chronic_disease_list : ''
          that.medicalData.policyList = data.result.policy_list !== null ? data.result.policy_list : ''
          if (data.result.hospital_visits!= null && data.result.hospital_visits.prescribe_list !== null) {
            that.medicalData.prescribeList = data.result.hospital_visits.prescribe_list
          }
          this.getMedicalUserInfo(result)
        } else {
          this.$toast.clear()
          this.$toast.fail(data.msg)
        }
      })
    },
    getMedicalCare() {
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
      });
      this.$http({
        url: this.$http.adornUrl(`/wxapp/QTXQ/getHealthRecord`),
        method: 'post',
        params: this.$http.adornParams({
          idNumber: that.dataForm.idNumber
        })
      }).then(({data}) => {
        if (data.code == 0) {
          that.medicalData.card = data.result.medical_card_no
          if (data.result.family_doctor !== null ) {
            that.medicalData.familyDoctor.doctorId = data.result.family_doctor.doctor_id
            that.medicalData.familyDoctor.doctorName = data.result.family_doctor.doctor_name
            let arr = data.result.family_doctor.sign_start_date.split(' ')[0].split('/')
            if (arr[1] <= 9) {
              arr[1] = '0' + arr[1]
            }
            if (arr[0] <= 9) {
              arr[0] = '0' + arr[0]
            }
            let startDate = arr[2] + '-' + arr[1] + '-' + arr[0]
            that.medicalData.familyDoctor.startTime = startDate

            let arr2 = data.result.family_doctor.sign_end_date.split(' ')[0].split('/')
            if (arr2[1] <= 9) {
              arr2[1] = '0' + arr2[1]
            }
            if (arr2[0] <= 9) {
              arr2[0] = '0' + arr2[0]
            }
            let endDate = arr2[2] + '-' + arr2[1] + '-' + arr2[0]
            that.medicalData.familyDoctor.endTime = endDate
          }
          that.medicalData.hospitalVisits.visitDate = data.result.hospital_visits !== null ? data.result.hospital_visits.visit_date : ''
          that.medicalData.hospitalVisits.diagnosis = data.result.hospital_visits !== null ? data.result.hospital_visits.diagnosis : ''
          that.medicalData.prescribeList = data.result.hospital_visits !== null ? data.result.hospital_visits.prescribe_list : ''
          that.medicalData.healthCheck.summary = data.result.health_check !== null ? data.result.healthCheck.summary : ''
          that.medicalData.healthCheck.diagnosis = data.result.health_check !== null ? data.result.healthCheck.diagnosis : ''
          that.medicalData.healthCheck.checkDate = data.result.health_check !== null ? data.result.healthCheck.check_date : ''
          that.medicalData.healthCheck.suggest = data.result.health_check !== null ? data.result.healthCheck.suggest : ''
          that.medicalData.chronicDiseaseList = data.result.chronic_disease_list !== null ? data.result.chronic_disease_list : ''
          that.medicalData.policyList = data.result.policy_list !== null ? data.result.policy_list : ''
          this.$toast.clear()
        } else {
          this.$toast.clear()
          this.$toast.fail(data.msg)
        }
      })
    },
    getMedicalUserInfo(result){
      this.$http({
        url: this.$http.adornUrl(`/wxapp/QTXQ/userInfo`),
        method: 'post',
        params: this.$http.adornParams({
          idNumber: result
        })
      }).then(({data}) => {
        if (data.code == 0) {
          // 用户基本信息
          that.dataForm.id = data.userInfo.id || 0
          that.dataForm.name = data.userInfo.name || ""
          that.dataForm.mobile = data.userInfo.mobile || ""
          that.dataForm.fixedLine = data.userInfo.fixedLine || ""
          that.dataForm.sex = data.userInfo.sex || ""
          that.dataForm.nationality = data.userInfo.nationality || ""
          that.dataForm.nation = data.userInfo.nation || ""
          that.dataForm.idNumber = data.userInfo.idNumber || ""
          that.dataForm.birthday = data.userInfo.birthday || ""
          that.dataForm.regAddress = data.userInfo.regAddress || ""
          that.dataForm.nowAddress = data.userInfo.nowAddress || ""
          that.dataForm.headImg = data.userInfo.headImg || ""
          that.dataForm.company = data.userInfo.company || ""
          that.dataForm.death = data.userInfo.death || "0"
          that.dataForm.deathDate = data.userInfo.deathDate || ""
          that.show.nationalityStr = data.userInfo.nationalityStr || "未知"
          that.show.nationStr = data.userInfo.nationStr || "未知"
          that.show.idNumberTypeStr = data.userInfo.idNumberTypeStr || ""
          that.dataForm.education = data.userInfo.education || ""
          that.dataForm.spouseIdNumber = data.userInfo.spouseIdNumber || ""
          that.dataForm.political = data.userInfo.political || ""
          that.dataForm.usedName = data.userInfo.usedName || ""
          that.dataForm.hometown = data.userInfo.nativePlace || ""
          that.dataForm.religious = data.userInfo.religious || ""
          that.dataForm.marriage = data.userInfo.marriage || ""
          that.dataForm.spouseIdNumberType = data.userInfo.spouseIdNumberType || ""
          that.show.spouseIdNumberType = data.userInfo.spouseIdNumberTypeStr || ""
          that.show.marriage = data.userInfo.marriageStr || '请选择'
          that.show.religious = data.userInfo.religiousStr || '请选择'
          that.show.education = data.userInfo.educationStr || '请选择'
          that.show.political = data.userInfo.politicalStr || '请选择'
          that.show.spouseIdNumber = data.userInfo.spouseIdNumberStr || '请选择'
          if (null != data.userInfo.headImg) {
            that.show.headImg = data.userInfo.headImg ? this.getImageStream(data.userInfo.headImg) : this.getImageStream("files/wx/images/content/headImg.png")
          }
          // 居民信息
          if (data.userRes !== null) {
            // that.orgValue = [data.userRes.province, data.userRes.city, data.userRes.county,
            //   data.userRes.street, data.userRes.community]
            that.dataForm.registryType = data.userRes.registryType + '' || ""
            that.dataForm.emMobile = data.userRes.emMobile || ""
            that.dataForm.emContact = data.userRes.emContact || ""
            that.dataForm.resRemark = data.userRes.remark || ""
            that.dataForm.orgId = data.userRes.community || ""

            that.show.registryTypeStr = data.userRes.registryTypeStr || "未知"
          }
          console.log(that.dataForm)
          //debugger
          // 居民标签
          var resLabels = data.resLabels;
          var labels = []
          if (resLabels != null) {
            let shorts = that.dataForm.shorts
            for (let i in resLabels) {
              let resLabel = resLabels[i]
              if (resLabel.shortName) {
                shorts.push(resLabel.shortName)
              }
            }
            console.log(resLabels)
            labels = that.formatLabel(resLabels, true);
            console.log(labels)

            let tabs = [
              {
                key: 'userResInfo',
                title: '居民信息',
                content: 'Content of tab 1',
              },
              {
                key: 'houseRes',
                title: '房屋信息',
                content: 'Content of tab 2',
              }]

            if (shorts.indexOf("精") > -1) {
              that.control.psychosis = true
            }

            if (shorts.indexOf("访") > -1) {
              that.control.petition = true
            }
          }
          that.labels = labels

          let idsArray = labels.map((item) => {
            item["delete"] = false
            return item.ids
          })
          that.dataForm.labels = that.dataForm.labels.concat(idsArray)
          this.resHousesInfo()


          this.getUserinfoauxiliary()
          this.$toast.clear()
        } else {
          this.$toast.clear()
          this.$toast.fail(data.msg)
        }
      })
    },
    uploaddel(file, detail) {
      let imgUrls = this.dataForm.imgUrl.split(",");
      imgUrls.splice(detail, 1);
      this.dataForm.imgUrl = imgUrls.join();
    },
    // 上传图片
    uploadRead(file) {
      let that = this;
      this.$toast.loading({
        message: "上传中...",
        forbidClick: true,
        duration: 0,
        overlay: true,
      });
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file);
        formdata.append("path", "files/FAG/temporary/");
        this.$http({
          url: this.$http.adornUrl("/wxapp/file/upload"),
          method: "post",
          data: formdata,
        }).then(({ data }) => {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            this.dataForm.imgUrl = this.dataForm.imgUrl
                ? this.dataForm.imgUrl + "," + data.fileMessage.relativePath
                : data.fileMessage.relativePath;
            return true;
          } else {
            this.$toast.fail(data.msg);
          }
        });
      })
    },
    getUserinfoauxiliary() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/fAndG/fguserrelationship/info/${this.dataForm.id}`),
        method: 'post',
      }).then(({data}) => {
        if (data.code == 0) {
          if (data.info != null){
            this.show.orgStr=data.info.communityStr
            this.dataForm.orgIdHZ=data.info.community
            this.dataForm.orgIdCS=data.info.community
            this.dataForm.subarea=data.info.subarea
            this.dataForm.name=data.info.userName
            this.dataForm.contractor=data.info.contractor
            this.dataForm.idNumber=data.info.idNumber
            this.dataForm.mobile=data.info.mobile
            this.show.orgStr=data.info.numTreeList
            this.zoonList = data.info.numTreeList
            this.dataForm.buildingIdWatch=data.info.buildingId
            // this.dataForm.buildingIds.push(data.info.buildingId)
            this.dataForm.houseId = data.info.houseId
            this.dataForm.userId=data.info.userId
            this.dataForm.fgYear=data.info.fgYear
            this.dataForm.fgQuarter=data.info.fgQuarter
            this.show.fgYear=data.info.fgYear+'年'
            this.show.fgQuarter='第'+data.info.fgQuarter+'季度'
            this.dataForm.subarea = data.info.subarea
            // this.dataForm.remark=data.info.remark
            data.info.numTreeList.forEach((item,index)=>{
              if(item.children){
                item.children.forEach((item1,index1)=>{
                  if(!item1.children){
                    let obj = {parentSpecies:item.value, id:item1.parameters.numVo.id||0, species:item1.value, area:item1.parameters.numVo.area,number:item1.parameters.numVo.number||0,salesVolume:item1.parameters.numVo.salesVolume||0,furiId:item1.parameters.numVo.furiId||0,speciesName:item1.label,userId:this.dataForm.userId}
                    this.numList.push(obj)
                  }
                  else{
                    item1.children.forEach((item2,index2)=>{
                      let obj = {parentSpecies:item.value, id:item2.parameters.numVo.id||0, species:item2.value, area:item2.parameters.numVo.area,number:item2.parameters.numVo.number||0,salesVolume:item2.parameters.numVo.salesVolume||0,furiId:item2.parameters.numVo.furiId||0,speciesName:item2.label,userId:this.dataForm.userId}
                      this.numList.push(obj)
                    })
                  }
                })
              }
              else{
                let obj = {parentSpecies:item.value, id:item.parameters.numVo.id||0, species:item.value, area:item.parameters.numVo.area,number:item.parameters.numVo.number||0,salesVolume:item.parameters.numVo.salesVolume||0,furiId:item.parameters.numVo.furiId||0,speciesName:item.label,userId:this.dataForm.userId}
                this.numList.push(obj)
              }
            })
          }
        }
      })
    },
    getCarList() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/devicecar/list`),
        method: 'post',
        params: this.$http.adornParams({
          page: 1,
          limit: -1,
          orgId: that.$globalData.userInfo.orgId,
          userId: this.dataForm.id
        })
      }).then(({data}) => {
        if (data.code == 0) {
          that.dataForm.carList = data.page.list;
        }
      })
    },
    getInfoById () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/info`),
        method: 'post',
        params: this.$http.adornParams({
          id: parseInt(that.dataForm.id),
          orgId: that.$globalData.userInfo.orgId,
          userType: 1
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.dataFormInfo(data)

        }
      })
    },
    getInfoByIdNumber () {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/getByIdNumber`),
        method: 'post',
        params: this.$http.adornParams({
          idNumber: that.dataForm.idNumber,
          orgId: that.$globalData.userInfo.orgId,
          userType: 1
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.dataFormInfo(data)
        } else {
          this.show.birthdayStr = getBirthdayFromIdCard(this.dataForm.idNumber)
          this.dataForm.birthday = getBirthdayFromIdCard(this.dataForm.idNumber)
          this.dataForm.sex = getSexFromIdCard(this.dataForm.idNumber)
        }
      })
    },
    dataFormInfo(data) {
      if (data.code == 0) {
        that.show.orgStr=data.userRes.communityStr
        // 用户基本信息
        that.dataForm.id = data.userInfo.id || 0
        that.dataForm.name = data.userInfo.name || ""
        that.dataForm.mobile = data.userInfo.mobile || ""
        // that.dataForm.mobileInput = data.userInfo.mobile || ""
        that.dataForm.fixedLine = data.userInfo.fixedLine || ""
        that.dataForm.sex = data.userInfo.sex || ""
        that.dataForm.nationality = data.userInfo.nationality || ""
        that.dataForm.nation = data.userInfo.nation || ""
        that.dataForm.idNumber = data.userInfo.idNumber || ""
        that.dataForm.birthday = data.userInfo.birthday
        that.dataForm.nowAddressPcc = [data.userInfo.nowAddressProvince, data.userInfo.nowAddressCity, data.userInfo.nowAddressCounty].filter(i => i)
        that.dataForm.regAddressPcc = [data.userInfo.regAddressProvince, data.userInfo.regAddressCity, data.userInfo.regAddressCounty].filter(i => i)
        that.show.nowAddressPcc = that.dataForm.nowAddressPcc.join('/')
        that.show.regAddressPcc = that.dataForm.regAddressPcc.join('/')

        that.dataForm.cardName=data.userAuxiliary ? data.userAuxiliary.cardName : ''
        that.dataForm.cardOwnerName=data.userAuxiliary ? data.userAuxiliary.cardOwnerName : ''
        that.dataForm.cardNumber=data.userAuxiliary ? data.userAuxiliary.cardNumber : ''
        that.dataForm.regAddress = data.userInfo.regAddress || ""
        that.dataForm.nowAddress = data.userInfo.nowAddress || ""
        that.dataForm.regAddressCode = data.userInfo.regAddressCode
        that.dataForm.regAddressCodeName = data.userInfo.regAddressCodeName
        that.show.regCodeStr = data.userInfo.regAddressCode
        that.dataForm.nowAddressCode = data.userInfo.nowAddressCode
        that.dataForm.nowAddressCodeName = data.userInfo.nowAddressCodeName
        that.show.nowCodeStr = data.userInfo.nowAddressCode
        that.dataForm.headImg = data.userInfo.headImg || ""
        that.dataForm.company = data.userInfo.company || ""
        that.dataForm.death = data.userInfo.death || "0"
        that.dataForm.deathDate = data.userInfo.deathDate || ""
        that.show.nationalityStr = data.userInfo.nationalityStr || "未知"
        that.show.nationStr = data.userInfo.nationStr || "未知"
        that.show.idNumberTypeStr = data.userInfo.idNumberTypeStr || ""
        that.dataForm.idNumberType = data.userInfo.idNumberType || "1"
        that.dataForm.education = data.userInfo.education || ""
        that.dataForm.spouseIdNumber = data.userInfo.spouseIdNumber || ""
        that.dataForm.political = data.userInfo.political || ""
        that.dataForm.usedName = data.userInfo.usedName || ""
        that.dataForm.hometown = data.userInfo.nativePlace || ""
        that.dataForm.religious = data.userInfo.religious || ""
        that.dataForm.marriage = data.userInfo.marriage || ""
        that.dataForm.spouseIdNumberType = data.userInfo.spouseIdNumberType || ""
        that.dataForm.birthdayCode = data.userInfo.birthdayCode || ''
        that.dataForm.health = data.userInfo.health || ''
        that.show.healthStr = data.userInfo.healthStr || '请选择'
        that.dataForm.cremationDate = data.userInfo.cremationDate
        this.$nextTick(() => {
          that.dataForm.mobileCheck = data.userInfo.mobileCheck || 0
          that.dataForm.nowAddressCheck = data.userInfo.nowAddressCheck || 0
          that.dataForm.regAddressCheck = data.userInfo.regAddressCheck || 0
        })
        that.show.spouseIdNumberType = data.userInfo.spouseIdNumberTypeStr || ""
        that.show.marriage = data.userInfo.marriageStr || '请选择'
        that.show.religious = data.userInfo.religiousStr || '请选择'
        that.show.education = data.userInfo.educationStr || '请选择'
        that.show.political = data.userInfo.politicalStr || '请选择'
        that.show.spouseIdNumber = data.userInfo.spouseIdNumberStr || '请选择'
        if (null != data.userInfo.headImg) {
          that.show.headImg = data.userInfo.headImg ? this.getImageStream(data.userInfo.headImg) : this.getImageStream("files/wx/images/content/headImg.png")
        }
        // 居民信息
        if (data.userRes !== null && data.userRes !== undefined) {
          // that.orgValue = [data.userRes.province, data.userRes.city, data.userRes.county,
          //   data.userRes.street, data.userRes.community]
          this.userRes = data.userRes
          that.dataForm.registryType = data.userRes.registryType + '' || ""
          that.dataForm.emMobile = data.userRes.emMobile || ""
          that.dataForm.emContact = data.userRes.emContact || ""
          that.dataForm.resRemark = data.userRes.remark || ""
          that.dataForm.orgId = data.userRes.community || ""

          that.show.registryTypeStr = data.userRes.registryTypeStr || "未知"
          that.dataForm.moveInDate = data.userRes.moveInDate || ''
          that.show.allCheck = data.userRes.allCheck
        }else {
          this.userRes = null
        }
        if (data.userAuxiliary !== null && data.userAuxiliary !== undefined) {
          that.dataForm.job = data.userAuxiliary.job
          that.dataForm.jobType = data.userAuxiliary.jobType
          that.show.jobType = data.userAuxiliary.jobTypeStr
          that.dataForm.insuredStatus = data.userAuxiliary.insuredStatus
          that.show.insuredStatus = data.userAuxiliary.insuredStatusStr
          that.dataForm.specialty = data.userAuxiliary.specialty || ''
          that.dataForm.militaryType = data.userAuxiliary.militaryType || ''
          that.show.militaryTypeStr = data.userAuxiliary.militaryTypeStr || '请选择'
          that.dataForm.unemploymentReason = data.userAuxiliary.unemploymentReason
          that.dataForm.retirementType = data.userAuxiliary.retirementType
          that.show.retirementType = data.userAuxiliary.retirementTypeStr || '请选择'
          that.dataForm.retirementReason = data.userAuxiliary.retirementReason
          that.dataForm.pension = data.userAuxiliary.pension
          that.show.pensionStr = data.userAuxiliary.pensionStr || '请选择'
          that.dataForm.deathCard = data.userAuxiliary.deathCard
          that.dataForm.cremationCard = data.userAuxiliary.cremationCard
          that.dataForm.deathAddress = data.userAuxiliary.deathAddress
          that.dataForm.deathReason = data.userAuxiliary.deathReason
        }
        console.log(that.dataForm)
        // 居民标签
        var resLabels = data.resLabels;
        var labels = []
        if (resLabels != null && resLabels != undefined) {
          let shorts = that.dataForm.shorts
          for (let i in resLabels) {
            let resLabel = resLabels[i]
            if (resLabel.shortName) {
              shorts.push(resLabel.shortName)
            }
          }
          //目前只有钱塘区需要智养功能
          if (this.$globalData.userInfo.county == 12345) {
            if (resLabels !== null && resLabels.length > 0) {
              resLabels.map(item => {
                if (item.path.indexOf(",2,") !== -1) {
                  this.control.oldShow = true
                  this.getMedicalCare()
                }
              })
            }
          }
          labels = that.formatLabel(resLabels, true);

          let tabs = [
            {
              key: 'userResInfo',
              title: '居民信息',
              content: 'Content of tab 1',
            },
            {
              key: 'houseRes',
              title: '房屋信息',
              content: 'Content of tab 2',
            }]

          if (shorts.indexOf("精") > -1) {
            that.control.psychosis = true
          }

          if (shorts.indexOf("访") > -1) {
            that.control.petition = true
          }
        }
        that.labels = labels

        let idsArray = labels.map((item) => {
          item["delete"] = false
          return item.ids
        })
        that.dataForm.labels = that.dataForm.labels.concat(idsArray)
        if(data.userResList && data.userResList.length > 0){
          that.dataForm.communityList = data.userResList
        }

        this.$nextTick(() => {
          if (this.$refs.fieldView) {
            this.$refs.fieldView.init(data.userRes.id, 1, that.dataForm.orgId)
          }
        })
      }
    },
    //居民房屋列表初始化
    resHousesInfo() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/res/houseRes/list`),
        method: 'get',
        params: this.$http.adornParams({
          userId: parseInt(that.dataForm.id)
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let userResHouses = data.userResHouses
          // let u = JSON.parse(JSON.stringify(userResHouses))
          for (let i in userResHouses) {
            let house = userResHouses[i]
            house["initSubarea"] = house.orgIds[house.orgIds.length - 1];
            house["initSubareaName"] = house.subareaName
            house["initHouseId"] = house.houseId
            house["initFullName"] = house.fullName
            house["initRelationship"] = house.relationship
            house["initRelationshipStr"] = house.relationshipStr
            house["initUseType"] = house.useType
            house["initUseTypeStr"] = house.useTypeStr
            house["initNowLive"] = house.nowLive
            house['houses'] = []
            house['subarea'] = house.orgIds[house.orgIds.length - 1];
            house['subareaName'] = house.subareaName;
            house['status'] = 0;
            house['live'] = house.nowLive == 1 ? '是' : '否';
            house['remark'] = house.remark;
            house['manage'] = house.manage;
            that.checkedHouses.push({
              'index': parseInt(i),
              'houseId': house.houseId
            })
          }
          that.resHouses = data.userResHouses,
              that.houseChange(that.resHouses)
        }
      })
    },
    selectInfo() {
      // this.userSelect();
      this.houseSelect();
      this.getDictList();
    },

    getDictList() {

      // getVirtualDict('political', (dict) => {
      //   this.selectList.politicalList = dict
      // })
      // getVirtualDict('religious', (dict) => {
      //   this.selectList.religiousList = dict
      // })
      // getDictTree({code: 'marriage'}, (dict) => {
      //   this.selectList.marriageList = dict
      // })
      // getDictTree({code: 'education'}, (dict) => {
      //   this.selectList.educationList = dict
      // })
      // getDictTree ({code: 'nationAddress'}, (res) => {
      //   this.cityData = res
      // })
      // getVirtualDict('militaryType', (dict) => {
      //   this.selectList.militaryTypeList = dict
      // })
      // getVirtualDict('insuredStatus', (dict) => {
      //   this.selectList.insuredStatusList = dict
      // })
      // getVirtualDict('retirementType', (dict) => {
      //   this.selectList.retirementTypeList = dict
      // })
      // getbelongGrid({ orgId: this.$orgId }, function (e) {
      //   that.selectList.gridList = e
      // });
      // getVirtualDict('jobType', (dict) => {
      //   this.selectList.jobTypeList = dict
      // })
      // getVirtualDict('health', (dict) => {
      //   this.selectList.healthList = dict
      // })

        if (this.$globalData.userInfo.depth == 4) {
          getBelongCommunity(this.$orgId, function (e) {
            that.selectList.orgList = e.map(item => {
              return {
                value: item.value,
                label: item.label,
              }
            })
          });
        } else {

          that.dataForm.communityList = [{
            orgId: this.$orgId,
            orgName: this.$globalData.userInfo.userName,
          }]

      }
    },
    userSelect() {
      //户籍性质
      // this.$http({
      //   url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/registryType`),
      //   method: 'get',
      //   params: this.$http.adornParams({})
      // }).then(({data}) => {
      //   if (data.code == 0) {
      //     data.registryTypes.map((type) => {
      //       type.value = type.value + ""
      //     })
      //     that.selectList.registryTypeList = data.registryTypes
      //   }
      // })

      //居民标签
      listComRightLabel(function (labels) {
        that.selectList.userCommunityLabelTree = labels
      })

      //身份证类型
      getVirtualDict("idNumberType", function (virtualDictList) {
        that.selectList.idNumberTypeList = virtualDictList
      })
    },
    //房屋信息select加载
    houseSelect() {
      //所有小区
      getbelongSubArea(this.$globalData.userInfo.orgId, function (subAreaList) {
        that.selectList.subareaList = subAreaList

      })

      //居住房屋
      this.$http({
        url: this.$http.adornUrl(`/wxapp/building/house/liveWhichTree`),
        method: 'get',
        params: this.$http.adornParams({
          communityId: this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          that.selectList.allHouseTree = data.liveWhichTree
        }
      })

      // //与户主关系
      // this.$http({
      //   url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/relationships`),
      //   method: 'get',
      //   params: this.$http.adornParams()
      // }).then(({data}) => {
      //   if (data.code == 0) {
      //     data.relationships.map((r) => {
      //       r.value = r.value + ""
      //     })
      //     that.selectList.relationshipList = data.relationships
      //   }
      // })

      //房屋属性
      // this.$http({
      //   url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/useType`),
      //   method: 'get',
      //   params: this.$http.adornParams()
      // }).then(({data}) => {
      //   if (data.code == 0) {
      //
      //     data.useTypes.map((u) => {
      //       u.value = u.value + ""
      //     })
      //     that.selectList.useTypeList = data.useTypes
      //   }
      // })
    },

    headImgButton(e) {
      console.log(e)
      if (!that.control.disable1) {
        let path = "files/userHeadImg/temporary"
        this.$toast.loading({
          message: '上传中...',
          forbidClick: true,
          duration: 0,
          overlay: true
        })
        upload(e.file, path, null, function (res) {
          // alert(JSON.stringify(res))
          that.show.headImg = getImageStream(res.fileMessage.relativePath)
          that.dataForm.temporaryHeadImg = res.fileMessage.relativePath
          that.dataForm.temporaryHeadImgSuffix = res.fileMessage.suffix
          that.$toast.clear()
        })
      }
    },
    cardIdIdentified(e) {
      let formdata = new FormData();
      formdata.append("file", e.file)
      this.$http({
        url: this.$http.adornUrl('/wxapp/file/cardIdIdentified'),
        method: 'post',
        header: {
          appid: this.$globalData.userInfo.depth == 4 ? 'wx245d433a2e482e22' : 'wxcd8dbbf3f2ba3b14'
        },
        data: formdata
      }).then(({data}) => {
        if (data.code == 0) {
          this.dataForm.name = data.message.name
          this.dataForm.idNumberType = 1
          this.dataForm.idNumber = data.message.id
          this.dataForm.regAddress = data.message.addr
          this.dataForm.birthday = data.message.birthday
          this.dataForm.sex = data.message.gender == "男" ? 1 : 2
          this.dataForm.nation = data.message.nation
          this.show.nation = data.message.nationality
          this.show.idNumberTypeStr = '身份证'
          if (data.message.province) {
            this.dataForm.regAddressPcc = [data.message.province, data.message.city, data.message.distinct]
            this.show.regAddressPcc = this.dataForm.regAddressPcc.join('/')
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    goNation() {
      if (!this.control.disable1) {
        // this.$router.push('/nation')
        this.control.nationShow = true
      }
    },
    goNationality() {
      if (!this.control.disable1) {
        this.control.nationalityShow = true
      }
    },
    getImageStream(e) {
      return getImageStream(e)
    },
    showIdNumberType() {
      console.log(this.control.disable1)
      if (!this.control.disable1) {
        this.control.idNumberType = true
      }
    },
    idNumberType(e) {
      this.dataForm.idNumberType = parseInt(e.value)
      this.show.idNumberTypeStr = e.label
      this.control.idNumberType = false
    },
    spouseIdNumberTypeConfirm(e) {
      this.dataForm.spouseIdNumberType = parseInt(e.value)
      this.show.spouseIdNumberType = e.label
      this.control.spouseIdNumberType = false
    },
    showBirthday() {
      if (!this.control.disable1) {
        this.control.birthday = true
      }
    },
    birthdayCalendar(e) {
      let date = formatterMonth(e)
      this.control.birthday = false
      this.show.birthdayStr = date
      this.dataForm.birthday = date
      this.currentBirthDay = date
    },
    formatDate(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      if (type === 'hour') {
        return val + '时'
      }
      if (type === 'minute') {
        return val + '分'
      }
      return val
    },
    showOrg() {
      if (!this.control.disable1) {
        this.control.orgShow = true
      }
    },
    showtype(){
      if (!this.control.disable1) {
        this.control.typeshow = true
      }
    },
    fgQuartertype(){
      if (!this.control.disable1) {
        this.control.fgQuarter = true
      }
    },
    yeartype(){
      if (!this.control.disable1) {
        this.control.fgYear = true
      }
    },
    gridUsertype(){
      if (!this.control.disable1) {
        this.control.gridUser = true
      }
    },
    showRegistryType() {
      if (!this.control.disable1) {
        this.control.registryType = true
      }
    },
    registryType(e) {
      this.dataForm.registryType = parseInt(e.value)
      this.show.registryTypeStr = e.label
      this.control.registryType = false
    },
    orgConfirm(e) {
      this.dataForm.orgIdHZ=parseInt(e.value)
      this.dataForm.orgId = parseInt(e.value)
      // this.getWgList(this.dataForm.orgId)
      // this.getleader(this.dataForm.orgId)
      this.show.orgStr = e.label
      this.getBelong(0,parseInt(e.value))
      this.show.community = '请选择'
      this.dataForm.community = ''
      this.control.orgShow = false

    },
    typeConfirm(e){
      this.dataForm.type = parseInt(e.value)
      this.show.type = e.label
      this.control.typeshow = false
    },
    fgQuarterConfirm(e){
      this.dataForm.fgQuarter = parseInt(e.value)
      this.show.fgQuarter = e.label
      this.control.fgQuarter = false
    },
    yearConfirm(e){
      this.dataForm.fgYear = parseInt(e.value)
      this.show.fgYear = e.label
      this.control.fgYear = false
    },
    gridUserConfirm(e){
      this.dataForm.gridUserId = parseInt(e.value)
      this.show.gridUser = e.label
      this.control.gridUser = false
    },
    getWgList(val =0){
      let orgid=val==0?this.$globalData.userInfo.orgId:this.dataForm.orgId

      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/orgAdminListByManagerName`),
        method: 'get',
        params: this.$http.adornParams({
          orgId: orgid,
          managerName:  "网格员"
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let k=data.orgAdminList
          let newList = data.orgAdminList.map(item => { return { value: item.id, label: item.name} })

          this.selectList.WgList=newList
        }
      })
    },
    getleader(val =0){
      let orgid=val==0?this.$globalData.userInfo.orgId:this.dataForm.orgId

      this.$http({
        url: this.$http.adornUrl(`/wxapp/user/getOrgAllAdminList`),
        method: 'get',
        params: this.$http.adornParams({
          orgId: orgid,

        })
      }).then(({data}) => {
        if (data && data.code === 0) {

          let newList = data.list.map(item => { return { value: item.id, label: item.name} })
          this.selectList.leadershipList=newList
        }
      })
    },
    showDeathDate() {
      if (!this.control.disabled) {
        this.control.deathDate = true
      }
    },
    showCremationDate() {
      if (!this.control.disabled) {
        this.control.cremationDate = true
      }
    },
    showlabel() {
      // //debugger
      if (!this.control.disable2) {
        this.control.label = true
      }
    },
    showHometown() {
      if (!this.control.disabled) {
        this.control.Hometown = true
      }
    },
    showspouseIdNumberType() {
      if (!this.control.disable3) {
        this.control.spouseIdNumberType = true
      }
    },
    showEducation() {
      if (!this.control.disable3) {
        this.control.education = true
      }
    },
    showJobType () {
      if (!this.control.disable3) {
        this.control.jobType = true
      }
    },
    showPolitical() {
      if (!this.control.disable3) {
        this.control.political = true
      }
    },
    showHealth() {
      if (!this.control.disable3) {
        this.control.healthShow = true
      }
    },
    showReligious() {
      if (!this.control.disable3) {
        this.control.religious = true
      }
    },
    showInsuredStatus () {
      if (!this.control.disable4) {
        this.control.insuredStatus = true
      }
    },
    showRetirementType () {
      if (!this.control.disable4) {
        this.control.retirementType = true
      }
    },
    showPension () {
      if (!this.control.disable4) {
        this.control.pensionShow = true
      }
    },
    showMarriage() {
      if (!this.control.disable3) {
        this.control.marriage = true
      }
    },
    showMilitaryType() {
      if (!this.control.disable3) {
        this.control.militaryTypeShow = true
      }
    },
    hometownFinish(value) {
      console.log(value)
    },
    marriageConfirm(value) {
      this.control.marriage = false
      this.show.marriage = value.label
      this.dataForm.marriage = value.value
    },
    militaryTypeConfirm(value) {
      this.control.militaryTypeShow = false
      this.show.militaryTypeStr = value.label
      this.dataForm.militaryType = value.value
    },
    liveConfirm(e) {
      that.resHouses[this.houseIndex].initNowLive = e.value
      that.resHouses[this.houseIndex].live = e.label
      this.control.live = false
    },
    educationConfirm(value) {
      this.control.education = false
      this.show.education = value.label
      this.dataForm.education = value.value
    },
    jobTypeConfirm(value) {
      this.control.jobType = false
      this.show.jobType = value.label
      this.dataForm.jobType = value.value
    },
    politicalConfirm(value) {
      this.control.political = false
      this.show.political = value.label
      this.dataForm.political = value.value
    },
    healthConfirm(value) {
      this.control.healthShow = false
      this.show.healthStr = value.label
      this.dataForm.health = value.value
    },
    religiousConfirm(value) {
      this.control.religious = false
      this.show.religious = value.label
      this.dataForm.religious = value.value
    },
    moveInDateConfirm (value) {
      this.dataForm.moveInDate = formatterDate(value)
      this.control.moveInDate = false
    },
    unemploymentTimeConfirm(value) {
      this.dataForm.unemploymentTime = formatterDate(value)
      this.control.unemploymentTime = false
    },
    employmentConfirm(value) {
      this.control.employment = false
      this.show.employment = value.label
      this.dataForm.employment = value.value
    },
    insuredStatusConfirm(value) {
      this.control.insuredStatus = false
      this.show.insuredStatus = value.label
      this.dataForm.insuredStatus = value.value
    },
    retirementTypeConfirm(value) {
      this.control.retirementType = false
      this.show.retirementType = value.label
      this.dataForm.retirementType = value.value
    },
    pensionConfirm(value) {
      this.control.pensionShow = false
      this.show.pensionStr = value.label
      this.dataForm.pension = value.value
    },
    deathDateCalendar(e) {
      let date = formatterDate(e)
      this.control.deathDate = false
      this.dataForm.deathDate = date

    },
    cremationDateCalendar(e) {
      let date = formatterDate(e)
      this.control.cremationDate = false
      this.dataForm.cremationDate = date

    },
    selectGrid (e) {
      const {selectList, labelCondition, depth} = e
      if (e.labelCondition.length > 0) {
        this.dataForm.gridId = 0
        this.dataForm.miniGridId = 0
        this.selectMsg.gridName = labelCondition[0].label;
        this.dataForm.gridId = e.labelCondition[0].fatherValue || parseInt(selectList)
        this.dataForm.miniGridId = e.labelCondition[0].fatherValue ? parseInt(selectList) : 0
      }
      this.control.gridShow = false
    },
    //标签级联的关闭
    labelOverlay() {
      // this.setData({ 'control.communityLabelCascader= false })
      let temporaryLabel = that.temporaryLabel[0]
      if (!temporaryLabel) {
        return
      }
      for (let i in that.labels) {
        let label = that.labels[i]
        if (label.value == temporaryLabel.value) {
          return
        }
      }

      temporaryLabel["delete"] = false

      that.labels.push(temporaryLabel)
      that.dataForm.labels.push(temporaryLabel.ids)
    },
    //标签级联重置
    labelClose(e) {
      this.control.label = false
    },
    labelFinish(e) {
      let value = []
      e.selectedOptions.map(e => value.push(e.value))
      let map = {}
      map["value"] = value
      map['options'] = e.selectedOptions
      let temporaryLabel = this.formatLabel(map, false)
      that.temporaryLabel = temporaryLabel
    },
    //删除标签
    removeLabel(value) {
      for (let i in that.dataForm.labels) {
        let label = that.dataForm.labels[i] + ''
        let labelArray = label.split(",")
        let id = labelArray[labelArray.length - 1]
        if (id == value) {
          that.dataForm.labels.splice(i, 1)
        }
      }

      for (let i in that.labels) {
        let label = that.labels[i]
        if (label.value == value) {
          that.labels[i]["delete"] = true
        }
      }
      that.dataForm.labels = that.dataForm.labels
      that.labels = that.labels
      this.$forceUpdate()
    },
    /**
     * 处理标签显示效果\n
     *
     * @param resLabels
     * @param isOld 是否后端传入的旧数据
     * @returns {[]}
     */
    formatLabel: function (resLabels, isOld) {
      var labels = [];
      if (isOld) {
        for (var i = 0; i < resLabels.length; i++) {
          //是否存在第二级
          var exists2 = false;
          if (resLabels[i].parentId == 0) {
            for (var j = 0; j < resLabels.length; j++) {
              if (resLabels[j].parentId == resLabels[i].id) {
                exists2 = true;
                //是否存在第三级标签
                var exists3 = false;
                for (var k = 0; k < resLabels.length; k++) {
                  if (resLabels[k].parentId == resLabels[j].id) {
                    exists3 = true;
                    labels.push({
                      value: resLabels[k].id,
                      label: resLabels[k].name,
                      name: resLabels[i].name + "：" + resLabels[j].name + "(" + resLabels[k].name + ")",
                      ids: resLabels[i].id + "," + resLabels[j].id + "," + resLabels[k].id,
                      rightShow: resLabels[k].rightShow,
                      rightUpdate: resLabels[k].rightUpdate
                    })
                  }
                }
                if (!exists3) {
                  //不存在第三级，则加入第二级
                  labels.push({
                    value: resLabels[j].id,
                    label: resLabels[j].name,
                    name: resLabels[i].name + "：" + resLabels[j].name,
                    ids: resLabels[i].id + "," + resLabels[j].id,
                    rightShow: resLabels[j].rightShow,
                    rightUpdate: resLabels[j].rightUpdate
                  })
                }
              }
            }
            if (!exists2) {
              //不存在第二级，则加入第一级
              labels.push({
                value: resLabels[i].id,
                label: resLabels[i].name,
                name: resLabels[i].name,
                ids: resLabels[i].id,
                rightShow: resLabels[i].rightShow,
                rightUpdate: resLabels[i].rightUpdate
              })
            }
          }
        }
      } else {
        let ids = resLabels.value
        let options = resLabels.options;
        if (ids.length == 1) {
          let option = options[0]
          //只有第一级
          labels.push({
            value: option.value,
            label: option.label,
            name: option.label,
            ids: option.value,
            rightShow: true,
            rightUpdate: true
          })
        } else if (ids.length == 2) {
          let option1 = options[0]
          let option2 = options[1]
          //不存在第三级，则加入第二级
          labels.push({
            value: option2.value,
            label: option2.label,
            name: option1.label + "：" + option2.label,
            ids: ids.join(","),
            rightShow: true,
            rightUpdate: true
          })
        } else if (ids.length == 3) {
          let option1 = options[0]
          let option2 = options[1]
          let option3 = options[2]
          //不存在第三级，则加入第二级
          labels.push({
            value: option3.value,
            label: option3.label,
            name: option1.label + "：" + option2.label + "(" + option3.label + ")",
            ids: ids.join(","),
            rightShow: true,
            rightUpdate: true
          })
        }
      }
      return labels;
    },
    //编辑
    redact() {
      //debugger
      this.control.disabled = false
    },
    // 删除
    moveOut() {
      this.$dialog.confirm({
        message: '确认迁出？',
      }).then(() => {
        if (true) {

          //户籍性质
          this.$http({
            url: this.$http.adornUrl(`/wxapp/user/res/moveOutCommunity`),
            method: 'get',
            params: this.$http.adornParams({
              id: that.dataForm.id,
              community: this.$globalData.userInfo.orgId
            })
          }).then(({data}) => {
            if (data.code == 0) {
              that.$toast.success('迁出成功')
              this.$router.go(-1)
            } else {
              that.$toast.fail(data.msg)
            }
          })
        }
      })
    },
    moveAway (id) {
      this.$dialog.confirm({
        title: '搬离',
        message: '确认该人员搬离吗',
      })
          .then(() => {
            this.$http({
              url: this.$http.adornUrl('/wxapp/user/res/houseRes/moveOut'),
              method: 'post',
              params: this.$http.adornParams({
                id: id
              })
            }).then(({data})=> {
              if (data.code == 0) {
                this.$toast.success({message: '成功'})
              }else {
                this.$toast.fail({message: data.msg})
              }
            },err=> {this.$toast.fail({message: err})})
          })
    },
    // 核查
    dataCheck() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/res/check'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.dataForm.id,
          community: this.dataForm.orgId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.$toast.success({message: '核查成功'})
        }else {
          this.$toast.fail({message: data.msg})
        }
      },err=> {this.$toast.fail({message: err})})
    },
    // 前往居民关联房屋新增
    goHouse(item) {
      let data = encodeURIComponent(JSON.stringify(item))
      if (item || !!this.dataForm.id) {
        this.$router.push({path: '/userHouse-info', query: {data: item ? data : null, userId: this.dataForm.id}})
      } else {
        this.$toast.fail('请先保存基本信息！')
      }
    },
    //前往房屋
    gotohouse: function (index) {
      let houseId = that.checkedHouses[index].houseId
      this.$store.commit('setHouseId', houseId)
      console.log(that.checkedHouses[0].houseId)
      this.$router.push({path: '/house-view-details', query: {id: houseId}})
    },
    //前往车辆详情
    gotoCar: function (item) {
      if (item) {
        this.$router.push({path: '/resCar-add', query: {id: item.id, subarea: item.subarea, userId: item.userId}})
      } else {
        this.$router.push({path: '/resCar-add', query: {userId: this.dataForm.id}})
      }
    },
    //前往车辆违停记录
    illPark (id, userId, subarea, subareaStr) {
      this.$router.push({path: '/car-add', query: {id: id, subarea, active: '2'}})
      // this.$router.push({path: '/car-illPark', query: {carId: id, userId, subarea, subareaStr, isEdit: true}})
    },
    //前往关系详情
    gotoRelation (item, type, isEdit) {
      let data = encodeURIComponent(JSON.stringify(item))
      if (item || !!this.dataForm.id) {
        this.$router.push({path: '/relation-add', query: {data, userId: this.dataForm.id, type: type,isEdit}})
      } else {
        this.$toast.fail('请先保存基本信息！')
      }
    },
    //添加房屋
    addCard() {
      if (!that.control.disabled) {
        let has = false;//末尾存不存在迁出的
        let index = this.resHouses.length
        for (let i = (this.resHouses.length - 1); i >= 0; i--) {
          let res = this.resHouses[i]
          // 如果存在了迁出,删除记录，则直接插入到迁出删除记录的上一个
          if (((res.moveOut === 0 && res.status === 0)) && has) {
            if (i !== (this.resHouses.length - 1)) {
              index = i + 1
              break
            }
          } else if (res.moveOut === 1 || res.status === 1) {
            has = true
            if (i === 0) {
              // 如果第一个就是迁出或删除的，则直接插入
              index = 0
              break
            }
          }
        }
        this.resHouses.splice(index, 0, {
          id: 0,
          subarea: '',
          houseId: '',
          relationship: 0,
          useType: 0,
          nowLive: 1,
          remark: '',
          houses: [],
          moveOut: 0,
          registryTypeStr: "未知",
          subareaName: "请选择",
          fullName: "请选择",
          relationshipStr: "请选择",
          useTypeStr: "请选择",
          status: 0,
          live: ''
        })
        // this.$set(that.resHouses,that.resHouses)

        this.houseChange(that.resHouses)
      }

    },
    //删除房屋
    removeCard(index) {
      if (!that.control.disabled) {
        this.$dialog.confirm({
          message: '确认删除？',
        }).then(() => {
          if (true) {
            let resHouse = that.resHouses.splice(index, 1)
            resHouse[0].status = 1
            that.resHouses.push(resHouse[0])
            that.resHouses = that.resHouses

            // this.data.dataForm.fetHouses.splice(index, 1)
            let checkedHouses = that.checkedHouses
            let isRemove = false
            let removeIndex = 99
            for (let i in checkedHouses) {
              let checkedHouse = checkedHouses[i]
              if (checkedHouse.index == index) {
                that.checkedHouses.splice(index, 1)
                removeIndex = i
                isRemove = true
                break
              }
            }
            if (isRemove === true) {
              for (let i = removeIndex; i < checkedHouses.length; i++) {
                that.checkedHouses[i].index--
              }
            }
          }
          this.houseChange(that.resHouses)
        })
      }
    },
    //迁出
    moveOutCard(index) {
      if (!that.control.disabled) {
        this.$dialog.confirm({
          message: '确认迁出？',
        }).then(() => {
          if (true) {
            // this.data.dataForm.resHouses.splice(index, 1)
            // 把迁出的数据移到最后面
            let resHouse = that.resHouses.splice(index, 1)
            resHouse[0].moveOut = 1
            that.resHouses.push(resHouse[0])
            that.resHouses = that.resHouses
            //
            let checkedHouses = that.checkedHouses
            let isRemove = false
            let removeIndex = 99
            for (let i in checkedHouses) {
              let checkedHouse = checkedHouses[i]
              if (checkedHouse.index == index) {
                checkedHouses.splice(index, 1)
                removeIndex = i
                isRemove = true
                break
              }
            }
            if (isRemove === true) {
              for (let i = removeIndex; i < checkedHouses.length; i++) {
                checkedHouses[i].index--
              }
            }
          }
          this.houseChange(that.resHouses)
        })

      }
    },
    //房屋信息改变
    houseChange(houseList) {
      if (!houseList) {
        this.control.showNew = false
      } else {
        if (houseList.length === 0) {
          this.control.showNew = true
        } else {
          if (houseList[0].status === 1 || houseList[0].moveOut === 1) {
            this.control.showNew = true
          } else {
            this.control.showNew = false
          }
        }
      }
      that.control.showNew = that.control.showNew
    },

    subareaShow(index) {
      // //debugger
      if (!this.control.disabled) {
        this.houseIndex = index
        this.control.subareaPopup = true
      }
    },
    subareaSelect(e) {
      that.resHouses[this.houseIndex].subarea = e.value
      that.resHouses[this.houseIndex].subareaName = e.label
      //清空居住房屋
      that.resHouses[this.houseIndex].fullName = "请选择"
      that.resHouses[this.houseIndex].houseId = ""
      this.resHouses = that.resHouses
      this.control.subareaPopup = false
    },
    //显示居住信息
    houseTreeShow(index) {
      if (!that.control.disabled) {
        // //debugger
        let subarea = that.resHouses[index].subarea
        this.houseIndex = index
        if (!!(subarea)) {
          let allHouseTree = that.selectList.allHouseTree
          allHouseTree.map((t) => {
            if (t.value == subarea) {
              that.selectList.houseTree = t.children
              return;
            }
          })
          this.control.houseTreeCascader = true
          this.checkedHouseIndex = index
        } else {
          this.$toast.fail("请先选择小区")
        }
      }

    },

    houseOnClose() {
      // this.control.houseTreeCascader= false
    },
    //居住信息改变
    houseCascaderChange(e) {
      // //debugger
      let a = (!!(e.selectedOptions[(e.selectedOptions.length - 1)].children))
      console.log(a)
      if (!a) {
        let houseId = null;
        if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 2) {
          houseId = e.selectedOptions[(e.selectedOptions.length - 1)].parameter;
        } else if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 4) {
          houseId = e.selectedOptions[(e.selectedOptions.length - 1)].value;
        } else {
          return
        }
        // 防止重复提交一个房间
        if (that.checkedHouses.length > 0) {
          // 先判断有没有
          let exist = false
          let checkedHouses = that.checkedHouses
          //debugger
          for (let i in checkedHouses) {
            let checkedHouse = checkedHouses[i]
            if (checkedHouse.houseId == houseId && this.houseIndex != i) {
              // 如有则提示禁止选择
              that.$toast.fail("房屋信息已存在")
              // that.cancel('房屋信息已存在')
              exist = true
              break
            }
          }
          // 如没有则添加已选择
          if (!exist) {
            let checkedHouses2 = this.checkedHouses
            let save = true
            for (let i in checkedHouses2) {
              let checkedHouse = checkedHouses2[i]
              if (checkedHouse[this.houseIndex] == this.houseIndex) {
                checkedHouse.houseId = houseId
                save = false
              }
            }
            if (save) {
              let a = {'index': this.houseIndex, 'houseId': houseId}
              this.checkedHouses.push(a)
            }

            if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 2) {
              this.resHouses[this.houseIndex].houseId = houseId;
              let org = e.selectedOptions[(e.selectedOptions.length - 1)].label;
              let fullName = org.substring(0, org.length - 1)
              this.resHouses[this.houseIndex].fullName = fullName
              this.resHouses = this.resHouses
              // that.$set(that.resHouses,that.resHouses)

            } else if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 4) {
              this.resHouses[this.houseIndex].houseId = houseId
              this.resHouses[this.houseIndex].fullName = e.selectedOptions[(e.selectedOptions.length - 1)].parameter
              this.resHouses = this.resHouses
              // that.$set(that.resHouses,that.resHouses)
            }

          }
        } else {
          if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 2) {
            this.resHouses[this.houseIndex].houseId = houseId;
            let org = e.selectedOptions[(e.selectedOptions.length - 1)].label;
            let fullName = org.substring(0, org.length - 1)
            this.resHouses[this.houseIndex].fullName = fullName
            this.resHouses = this.resHouses
            // that.$set(that.resHouses,that.resHouses)
          } else if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 4) {
            this.resHouses[this.houseIndex].houseId = houseId
            this.resHouses[this.houseIndex].fullName = e.selectedOptions[(e.selectedOptions.length - 1)].parameter
            this.resHouses = this.resHouses
            // that.$set(that.resHouses,that.resHouses)
          }
          let a = {'index': this.houseIndex, 'houseId': houseId}
          this.checkedHouses.push(a)
        }
      }
    },
    relationshipShow(index) {
      if (!this.control.disabled) {
        this.houseIndex = index
        this.control.relationship = true
      }
    },
    relationshipConfirm(e) {
      that.resHouses[this.houseIndex].relationship = e.value
      that.resHouses[this.houseIndex].relationshipStr = e.label
      that.control.relationship = false
    },
    useTypeShow(index) {
      if (!this.control.disabled) {
        this.houseIndex = index
        this.control.useType = true
      }
    },
    useTypeConfirm(e) {
      that.resHouses[this.houseIndex].useType = e.value
      that.resHouses[this.houseIndex].useTypeStr = e.label
      that.control.useType = false
    },
    sexConfirm(value) {
      this.dataForm.sex = value.value
      this.control.sex = false
    },
    //提交
    onSubmit(e) {

      if(this.dataForm.orgIdHZ==''&&this.depth==4){
        return this.$toast.fail('请选择社区')
      }

      if(this.dataForm.fgYear==''){
        return this.$toast.fail('请选择年份')
      }
      if(this.dataForm.fgQuarter==''){
        return this.$toast.fail('请选择季度')
      }
      // if(this.dataForm.subarea==''&&this.control.disable1){
      //   return this.$toast.fail('请选择小区')
      // }
      if(this.dataForm.houseId==''){
        return this.$toast.fail('请选择房屋')
      }

      if(this.depth==4&&this.dataForm.orgIdHZ!=this.dataForm.orgIdCS){
        return this.$toast.fail('两次所选社区不一致')
      }
      if(this.dataForm.contractor=='' || this.dataForm.contractor=='无户主信息'){
        return this.$toast.fail('无房主信息')
      }


      //校验必填
      this.$dialog.confirm({
        message: '确认提交？',
      }).then(() => {
        // //debugger
        let check = true

        if (check) {
          this.$http({
            url: this.$http.adornUrl(`/wxapp/fAndG/fguserrelationship/save`),
            method: 'post',
            data: this.$http.adornData({
              'id': that.dataForm.id || 0,
              'fgType':2,
              'houseId':this.dataForm.houseId,
              'userId':this.dataForm.contractor,//承包人
              'name': this.dataForm.name||0,
              'fgYearQuarter':this.dataForm.fgYear+'_'+this.dataForm.fgQuarter,
              'numList':this.numList,
              'street': this.$globalData.userInfo.street||0,
              'subarea': this.dataForm.subarea||0,
              'community': this.dataForm.community||0,
              'idNumber': that.dataForm.idNumber||0,
              'orgId': that.dataForm.orgId||0,
              'remark':this.dataForm.remark
            })
          }).then(({data}) => {
            if (data.code == 0) {
              this.$toast.success("保存成功")
              this.$router.go(-1)
              // that.uploadHouse(data.userId)
            } else {
              that.$toast.fail(data.msg)
            }
          })
        }
      })
      // setTimeout(function () {
      //   wx.redirectTo({
      //     url: '/pages/common/userRes/index'
      //   })
      // }, 1500)
    },
    //上传房屋
    uploadHouse(userId) {
      let resHousess = []
      for (let index in that.resHouses) {
        let resHouse = that.resHouses[index]
        let resHousee = {
          id: resHouse.id,
          userId: userId,
          subarea: resHouse.subarea,
          houseId: resHouse.houseId,
          relationship: resHouse.relationship,
          useType: resHouse.useType,
          nowLive: resHouse.nowLive,
          remark: resHouse.remark,
          moveOut: resHouse.moveOut,
          status: resHouse.status
        }
        resHousess.push(resHousee)
      }
      //debugger
      let check = true
      for (var i = 0; i < resHousess.length; i++) {
        if (!resHousess[i].subarea) {
          check = false
          this.$toast.fail("所在小区不能为空")
          break
        }
        if (!resHousess[i].houseId) {
          check = false
          this.$toast.fail("居住房屋不能为空")
          break
        }
        if (!resHousess[i].relationship && resHousess[i].relationship != 0) {
          check = false
          this.$toast.fail("与户主关系不能为空")
          break
        }
        if (!resHousess[i].useType && resHousess[i].useType != 0) {
          check = false
          this.$toast.fail("房屋属性不能为空")
          break
        }
      }

      if (check) {

        this.$http({
          url: this.$http.adornUrl(`/wxapp/user/res/houseRes/saveOrUpdate`),
          method: 'post',
          data: this.$http.adornData({
            resHouses: JSON.stringify(resHousess)
          })
        }).then(({data}) => {
          if (data.code == 0) {
            if (that.dataForm.id) {
              that.control.disabled = true
              this.$toast.success("编辑成功")
              this.$router.go(-1)
            } else {
              this.$toast.success("保存成功")
              this.$router.go(-1)
            }
          } else {
            this.$toast.fail(res.msg)
          }
        })
      }
    },
  }
}
</script>

<style lang="scss">

.van-popover {
  position: absolute;
  top: 168px !important;
  right: 30px !important;
}
.gridPopover {
  width: 580px;
  position: absolute;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.15);
  border-radius: 8px;
  .van-popover__action-text {
    color: #E9564B;
  }
}
</style>
<style lang="scss" scoped>
.newtitle{
  padding-top: 20px;
  //::v-deep .van-cell__title.van-field__label {
  // font-size: 2px !important;
  //}
}
.info {
  padding: 0;
}
.required {
  &::before {
    content: '*';
    color: red;
    position: absolute;
    left: 10px;
  }
}
.houseTypeDes {
  color: red;
  font-size: 24px;
  line-height: 26px;
  padding: 10px;
}
.content {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  margin-top: -0.3rem;
  margin-bottom: -0.3rem;
  background-color: #f5f5f5;
}

::v-deep .van-uploader, ::v-deep .van-uploader__input-wrapper {
  width: 100%;
}

.headImg {
  width: 104px;
  height: 104px;
  border-radius: 50%;
}

.van-cell {
  align-items: center;
}

::v-deep .van-collapse-item__content {
  padding: 0;
}

::v-deep .van-field__control--left {
  text-align: left !important;
}

.left > > > .van-icon {
  position: absolute;
  top: 0;
  right: 0;
}

.vanTag {
  margin: 0 0 5px 5px;
  border-radius: 5px;
  border: 1px solid #007AFF;
  background: rgba(0, 122, 255, 0.1);
  //padding-right: 20px;
  float: left;

  span {
    display: inline-block;
    padding: 0 17px;
    //margin-right: 20px;
    font-size: 24px;
    color: #007AFF;
    height: 56px;
    line-height: 56px;
    border-right: 1px solid #007AFF;
  }
}

.btnPosition {
  position: absolute;
  bottom: 20px;
  width: 720px;
  left: 15px;
}

.ww {
  width: 100%;
}

.userRes {
  height: 160px;
}

.pagemtop {
  margin-top: 30px;
}

.goto {
  float: right;
  margin-left: 20px;
}

.blue {
  color: #007AFF
}

.houses {
  padding-top: 20px;
  background-color: #f5f5f5;
}

.addHouse {
  text-align: center;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}

.addHouseFont {
  margin-left: 4px;
  font-size: 30px;
  color: #409EFF
}

.iconSize {
  font-size: 200px;
}

.bai {
  height: 200px;
}

.mini-font-size {
  font-size: 28px;
}
.inspect {
  //position: absolute;
  //top: 50%;
  //right: 70px;
  transform: translateY(-3%);
  margin-left: 20px;
  height: 60px;
  line-height: 60px;
  width: 120px;
  border-radius: 5px;
  color: #fff;
  border: none;
  cursor: pointer;
  z-index: 9;

}
.form-btn-view {
  position: unset !important;
}
.van-form {
  padding-bottom: 320px;
}
.custom {
  .van-button {
    position: absolute;
    right: 85px;
    top: 20px;
  }
  .check {
    position: absolute;
    right: 220px;
    top: 20px;
  }
  .arrowIcon {
    position: absolute;
    right: 32px;
    top: 0;
    line-height: 88px;
    color: #666;
    font-size: 32px;
  }
}
.scan-title {
  line-height: 96px;
  color: #333;
  padding: 0 30px;
  font-size: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}
.scan {
  width: 40px;
  height: 40px;
}
.scan-title ::v-deep .van-uploader, ::v-deep .van-uploader__input-wrapper {
  width: unset !important;
}
.headImgBtn ::v-deep .van-uploader, ::v-deep .van-uploader__input-wrapper {
  width: 100% !important;
}
.headPng {
  width: 120px;
  height: 120px;
}
.headImgBtn ::v-deep .van-cell__title span {
  color: #4581F8;
}
.headImgBtn ::v-deep .van-cell__value {
  text-align: right;
}
.form-btn-view {
  margin-top: 20px;
  .btns {
    background-color: #fff;
    margin-top: 0;
    padding: 20px 28px 20px;
  }
}
.addBtn {
  width: 268px;
  height: 258px;
}
.addBtn img {
  width: 268px;
  height: 258px;
}
.houseInfo {
  .content {
    height: 166px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    background-color: #fff;
    .top-text {
      width: 100% !important;
      margin-top: 24px;
      //line-height: 84px;
      img {
        width: 24px;
        height: 24px;
        margin-left: 6px;
      }
      .tip {
        position: absolute;
        right: 0;
      }
    }
    .bottom-text {
      width: 100% !important;
    }
  }
  .bottom-left {
    line-height: 88px !important;
    .van-button {
      width: 112px;
      height: 60px;
      color: #4581F8;
      border-radius: 8px;
      border: 2px solid #4581F8;
    }
  }
}
.family {
  margin-top: 40px;
  .content {
    height: 172px !important;
    .content-text {
      height: 120px !important;
      .top-text {
        font-size: 34px !important;
        font-weight: 600;
      }
      .bottom-text {
        width: 6rem !important;
        display: flex !important;
        -webkit-line-clamp: unset !important;
        font-size: 30px !important;
        margin-top: 34px !important;
        span {
          flex: 1;
          display: block;
          width: 7rem !important;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          padding-left: 10px;
        }
        .tel {
          margin-left: 10px !important;
        }
      }
    }
    .content-img {
      width: 120px !important;
      height: 120px !important;
    }
  }
}
.relation {
  .van-button {
    right: 30px !important;
  }
}
.relationAdd {
  width: 690px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  margin: 60px 30px 0;
  background: rgba(255,255,255,0);
  border-radius: 8px;
  border: 2px dashed #D9D9D9;
  font-size: 28px;
  img {
    width: 28px;
    height: 28px;
    transform: translateY(-4px);
  }
}
.tel {
  width: 40px;
  height: 40px;
}
.carManage {
  .title-right {
    line-height: 70px !important;
    .van-button {
      width: 152px;
      height: 52px;
      //padding: 8px 28px;
      background: #4581F8;
      border-radius: 8px;
      border: none;
      color: #fff;
      margin-bottom: 24px;
    }
  }
  .content {
    height: 134px !important;
    margin-top: unset !important;
    margin-bottom: unset !important;
    .content-text {
      width: 100%;
      height: unset !important;
    }
  }
}
.carText {
  position: relative;
  width: 512px;
  height: 88px;
  line-height: 88px;
  border-radius: 8px;
  text-align: center;
  margin: 0 auto;
  font-size: 40px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  span {
    position: absolute;
    top: 0;
    right: 0;
    width: 88px;
    height: 42px;
    line-height: 42px;
    border-radius: 0px 8px 0px 8px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
  }
}
.oilCar {
  background: rgba(69,129,248,0.12);
  color: #4581F8;
  span{
    background: linear-gradient(90deg, #76A4FF 0%, #4581F8 100%);
    padding: 4px 20px;
  }
}
.newEnergy {
  background: rgba(43,143,50,0.12);
  color: #2B8F32;
  span{
    background: linear-gradient(135deg, #58CC64 0%, #48975F 100%);
    padding: 4px 8px;
  }
}
.car-add {
  position: relative;
  width: 100%;
  height: 226px;
  background: #FFFFFF;
  box-shadow: 0px 0px 26px 0px rgba(234,234,234,0.41);
  img {
    //position: absolute;
    display: block;
    width: 440px;
    height: 214px;
    margin: 0 auto;
    //transform: translateX(42%);
  }
  .van-button {
    position: absolute;
    left: 20%;
    bottom: 24px;
    width: 512px;
    height: 80px;
    line-height: 80px;
    font-size: 28px;
    font-weight: 600;
    background: #4581F8;
    box-shadow: 0px 4px 22px 0px rgba(69,129,248,0.35);
    border-radius: 8px;
    img {
      display: unset !important;
      width: 32px;
      height: 32px;
      line-height: 80px;
      margin-right: 12px;
      margin-bottom: 5px;
    }
  }
}
.title {
  padding: 24px 30px 0;
  font-size: 34px;
  font-family: 'PingFangSC-Regular';
  font-weight: 400;
  color: #666666;
}
.titles{
  font-weight: bold;
}

</style>
